/*eslint-disable no-undef*/
import L10n from "./Locale";

const cache_enabled = false;

export function APIAdminRequest(func, data, stringifyObjects = true) {
    return APIRequest(func, data, stringifyObjects, API_URL + "admin-api.php");
}

export function APIRequest(func, data, stringifyObjects = true, url = API_URL) {
    return new Promise((resolve, reject) => {
        if (typeof data === "undefined") data = null;

		const request = new Request(url + "?action=" + func, {
            credentials: "include",
        });
        const form_data = new FormData();

        if (data)
            Object.keys(data).forEach(function(k) {
                if (typeof data[k] === "object" && stringifyObjects) {
                    form_data.append(k, JSON.stringify(data[k]));
                } else if (typeof data[k] === "array" && stringifyObjects) {
                    for (let i=0; i<data[k].length; i++) {
                        data[k][i] = JSON.stringify(data[k][i]);
                    }
                } else {
                    form_data.append(k, data[k]);
                }
            });

		form_data.append("locale", L10n.GetLanguage());

        fetch(request, {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: form_data,
        })
        .then(response => {
            response.text().then(text => {
                try {
                    try {
                        const json = JSON.parse(text);
                        resolve(json);
                    } catch(e) {
                        resolve(text);
                    }
                } catch (e) {
                    console.error(e);
                }
            })
            .catch(error => reject(error));
        });
    });
}
