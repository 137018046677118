require("../../css/admin-reservas.css");

import { useRef, useCallback, useState, useEffect, useContext } from "react";
import Calendar from "./Calendar";
import Helper from "../services/Helper";
import MaterialIcon from "./MaterialIcon";
import AdminService from "../services/Admin";
import AccordionTab from "./AccordionTab";
import InputSelect from "./InputSelect";
import JSONEditor from "./JSONEditor";
import LoadingIndicator from "./LoadingIndicator";
import { APIAdminRequest } from "../services/API";
import { DialogContext } from "../context/DialogContext";
import L10n from "../services/Locale";

export default function AdminDisponibilidad(props) {
	const [selectedDates, setSelectedDates] = useState([]);
	const [openNewRowDetailsIndex, setOpenNewRowDetailsIndex] = useState(-1);
	const [openRowDetailsIndex, setOpenRowDetailsIndex] = useState(-1);
	const [openRowEventIndex, setOpenRowEventIndex] = useState(-1);
	const [availabilityCalendar, setAvailabilityCalendar] = useState({});
	const [events, setEvents] = useState([]);
	const [unsavedAvailability, setUnsavedAvailability] = useState([]);
	const [timeTable, setTimeTable] = useState({});
	const [negocio, setNegocio] = useState({});
	const [savingInProgress, setSavingInProgress] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [newRowData, setNewRowData] = useState({});
	const [unsavedChanges, setUnsavedChanges] = useState(false);

	const dialogContext = useContext(DialogContext);
	const negocioID = props.negocio;

	function renderAvailabilityDetails(index, existingRow, event) {
		let price = negocio.price;
		let row = typeof existingRow !== "undefined" ? unsavedAvailability.find((v) => v.id == existingRow.id) || existingRow : null;
		const unsavedRow = existingRow ? unsavedAvailability.find((v) => v.id == row?.id) || row : newRowData;

		if (event && event.price != -1) price = event.price;
		if (row && row.price != -1) price = row.price;

		let endTime = "00:00";
		if (row) {
			const startTime = row.slot.split(" ")[1].substring(0, 5);
			const d = new Date();
			d.setHours(startTime.split(":")[0], parseInt(startTime.split(":")[1]) + parseInt(row.duration || 0));
			endTime = ("" + d.getHours()).padStart(2, "0") + ":" + ("" + d.getMinutes()).padStart(2, "0");
		}

		function onRowChanged(keys, values) {
			const newRow = { ...unsavedRow };
			keys.forEach((key, i) => {
				if (newRow[key] != values[i]) {
					setUnsavedChanges(true);
				}
				newRow[key] = values[i];
			});
			const newUnsavedAvailability = [...unsavedAvailability];
			if (unsavedAvailability.find((v) => v.id == newRow.id)) {
				newUnsavedAvailability[unsavedAvailability.findIndex((v) => v.id == newRow.id)] = newRow;
			} else {
				newUnsavedAvailability.push(newRow);
			}
			setUnsavedAvailability(newUnsavedAvailability);
		}

		function onPaxInputChanged(value) {
			if (existingRow) {
				const newPaxTotal = Math.max(1, parseInt(value));
				const difference = newPaxTotal - unsavedRow.pax_total;

				const keys = ["pax_total", "pax"];
				const values = [newPaxTotal, unsavedRow.pax + difference];

				onRowChanged(keys, values);
			} else {
				const newData = { ...newRowData };
				newData.pax_total = Math.max(1, value);
				const difference = (newData.pax_total || 1) - (newRowData.pax_total || 1);
				newData.pax = (newData.pax || 1) + difference;
				setNewRowData(newData);
			}
		}

		return (
			<div className={"availability-row-details" + (row ? " existing-row" : " new-row")} id={"row-details-" + index}>
				<div className="detalles">
					<div className="row plazas-row">
						<div className="plazas">
							<input
								defaultValue={unsavedRow?.pax_total || newRowData.pax || 1}
								min={1}
								onChange={(e) => {
									const value = e.target.value;
									onPaxInputChanged(value);
								}}
							/>{" "}
							{(unsavedRow.pax_total || newRowData.pax || 1) == 1 ? L10n.__("plaza") : L10n.__("plazas")}
							<div className="plus-minus">
								<div
									className="plus"
									onClick={(e) => {
										const $input = $(e.currentTarget).parent().parent().find("input");
										$input.val(Math.max(1, parseInt($input.val()) - 1));
										onPaxInputChanged($input.val());
									}}>
									<img src="/static/icons/minus.svg" />
								</div>
								<div
									className="minus"
									onClick={(e) => {
										const $input = $(e.currentTarget).parent().parent().find("input");
										$input.val(Math.max(1, parseInt($input.val()) + 1));
										onPaxInputChanged($input.val());
									}}>
									<img src="/static/icons/plus.svg" />
								</div>
							</div>
						</div>
						<div className="separator" />
						<div className="precio">
							<input
								defaultValue={(newRowData.price || price) / 100}
								type="currency"
								onChange={(e) => {
									const value = e.target.value;
									if (row) {
										onRowChanged(["price"], [value * 100]);
									} else {
										const newData = { ...newRowData };
										newData.price = value * 100;
										setNewRowData(newData);
									}
								}}
							/>{" "}
							<span>&euro;</span>
						</div>
					</div>
					<AccordionTab heading={L10n.__("Extras")} className="extras">
						<JSONEditor
							initialValue={newRowData.menu_extras}
							onChange={(data) => {
								if (row) {
									onRowChanged(["menu_extras"], [data]);
								} else {
									const newData = { ...newRowData };
									newData.menu_extras = data;
									setNewRowData(newData);
								}
							}}
							associative={true}
							keyTitle={L10n.__("Categoría")}
							properties={[{ title: L10n.__("Descripción"), optional: true, key: "description", type: "string" }]}
							listTitle={L10n.__("Productos")}
							listProperties={[
								{ title: L10n.__("Nombre"), key: "name", type: "string" },
								{ title: L10n.__("Precio"), key: "price", type: "string" },
								{ title: L10n.__("Descripción"), key: "description", type: "text" },
								{ title: L10n.__("Imagen"), key: "image_url", type: "image" }
							]}
						/>
					</AccordionTab>
				</div>
				<div className="horarios">
					{!row &&
						timeTable &&
						selectedDates.map((date, dateIndex) => {
							const isoDate = Helper.GetISODate(date);
							return (
								<div className="weekday-row" key={"date-" + dateIndex}>
									<div className="weekday-header">
										<div className="weekday-name">{date.toLocaleString(window.navigator.language, { weekday: "long" })}</div>
										<div className="time-start-label">{L10n.__("Hora inicio")}</div>
										<div className="time-end-label">{L10n.__("Hora fin")}</div>
									</div>
									<div className="slot-rows">
										{timeTable &&
											timeTable[isoDate] &&
											timeTable[isoDate].map((slot, slotIndex) => {
												const d = new Date();
												d.setHours(slot.time.split(":")[0], slot.time.split(":")[1] + parseInt(slot.duration));
												let endTime = ("" + d.getHours()).padStart(2, "0") + ":" + ("" + d.getMinutes()).padStart(2, "0");
												
												if (isNaN(endTime)) {
													endTime = slot.time;
												}

												return (
													<div className="slot-row" key={"slot-row-" + slotIndex}>
														<div className="time-start">
															<InputSelect
																comboMode={true}
																options={Helper.GenTimeOptions()}
																defaultValue={slot.time}
																onChange={(option) => {
																	const newTimeTable = { ...timeTable };
																	newTimeTable[isoDate][slotIndex].time = option;
																	setTimeTable(newTimeTable);
																}}
															/>
														</div>
														<div className="time-end">
															<InputSelect
																comboMode={true}
																disabled={!slot.endTimeEnabled}
																options={Helper.GenTimeOptions()}
																defaultValue={endTime}
																onChange={(option) => {
																	const d = new Date();
																	d.setHours(option.split(":")[0] - slot.time.split(":")[0], option.split(":")[1] - slot.time.split(":")[1]);
																	const newTimeTable = { ...timeTable };
																	newTimeTable[isoDate][slotIndex].duration = d.getHours() * 60 + d.getMinutes();
																	setTimeTable(newTimeTable);
																}}
															/>
														</div>
														{timeTable[isoDate].length > 1 && (
															<div
																className="slot-row-button delete-slot-row-button"
																onClick={(e) => {
																	const newTimeTable = { ...timeTable };
																	newTimeTable[isoDate].splice(slotIndex, 1);
																	setTimeTable(newTimeTable);
																}}>
																<MaterialIcon name="delete" />
															</div>
														)}
														<div
															className="toggle-end-time-button has-tooltip slot-row-button"
															data-tooltip={L10n.__("Definir hora de finalización")}
															onClick={(e) => {
																const newTimeTable = { ...timeTable };
																newTimeTable[isoDate][slotIndex].endTimeEnabled = !slot.endTimeEnabled;
																setTimeTable(newTimeTable);
															}}>
															<MaterialIcon name={slot.endTimeEnabled ? "schedule" : "history_toggle_off"} />
														</div>
													</div>
												);
											})}
										{!row && (
											<div
												className="add-slot-row-button"
												onClick={(e) => {
													const newTimeTable = { ...timeTable };
													newTimeTable[isoDate].push({ time: "00:00", duration: 0, endTimeEnabled: false });
													setTimeTable(newTimeTable);
												}}>
												<img src="/static/icons/plus.svg" /> {L10n.__("Añadir horario")}
											</div>
										)}
									</div>
								</div>
							);
						})}
					{row && (
						<div className="weekday-row">
							<div className="weekday-header">
								<div className="weekday-name">{Helper.CreateDateCompatible(unsavedRow.slot.split(" ")[0]).toLocaleString(window.navigator.language, { weekday: "long" })}</div>
								<div className="time-start-label">{L10n.__("Hora inicio")}</div>
								<div className="time-end-label">{L10n.__("Hora fin")}</div>
							</div>
							<div className="slot-rows">
								<div className="slot-row">
									<div className="time-start">
										<InputSelect
											comboMode={true}
											options={Helper.GenTimeOptions()}
											defaultValue={unsavedRow.slot.split(" ")[1].substring(0, 5)}
											onChange={(option) => {
												const keys = ["slot"];
												const values = [unsavedRow.slot.split(" ")[0] + " " + option];

												if (unsavedRow.endTimeEnabled) {
													const d = new Date();
													d.setHours(endTime.split(":")[0] - option.split(":")[0], endTime.split(":")[1] - option.split(":")[1]);
													keys.push("duration");
													values.push(d.getHours() * 60 + d.getMinutes() || null);
												}

												onRowChanged(keys, values);
											}}
										/>
									</div>
									<div className="time-end">
										<InputSelect
											disabled={unsavedRow.endTimeEnabled !== true}
											comboMode={true}
											options={Helper.GenTimeOptions()}
											defaultValue={endTime}
											onChange={(option) => {
												const d = new Date();
												d.setHours(option.split(":")[0] - unsavedRow.slot.split(" ")[1].split(":")[0], option.split(":")[1] - unsavedRow.slot.split(" ")[1].split(":")[1]);
												onRowChanged(["duration"], [d.getHours() * 60 + d.getMinutes() || null]);
											}}
										/>
									</div>
									<div
										className="toggle-end-time-button has-tooltip slot-row-button"
										data-tooltip={L10n.__("Definir hora de finalización")}
										onClick={(e) => {
											if (!unsavedRow.endTimeEnabled) {
												const d = new Date();
												d.setHours(endTime.split(":")[0] - unsavedRow.slot.split(" ")[1].split(":")[0], endTime.split(":")[1] - unsavedRow.slot.split(" ")[1].split(":")[1]);
												onRowChanged(["duration"], [d.getHours() * 60 + d.getMinutes() || null]);
											}

											onRowChanged(["endTimeEnabled"], [!unsavedRow.endTimeEnabled]);
										}}>
										<MaterialIcon name={unsavedRow.endTimeEnabled ? "schedule" : "history_toggle_off"} />
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className={"availability-actions" + (savingInProgress ? " disabled saving-in-progress" : "")}>
					{row && (
						<button
							className="btn"
							onClick={(e) => {
								if (savingInProgress) return;
								dialogContext.openDialog(L10n.__("¿Seguro que deseas cancelar esta reserva?"), L10n.__("Sí"), L10n.__("No"), accepted => {
									if (accepted) {
										setSavingInProgress(true);
										APIAdminRequest("delete-availability", { id: row.id }).then(() => {
											AdminService.GetAvailabilityForNegocio(negocioID).then((response) => {
												setEvents(response.negocio.events);
												setAvailabilityCalendar(response.calendar);
												setSavingInProgress(false);
												setUnsavedChanges(false);
												setOpenRowDetailsIndex(-1);
											});
										});
									}
								});
							}}>
							Borrar disponibilidad
						</button>
					)}
					<button
						className="btn btn-brown"
						onClick={(e) => {
							if (savingInProgress) return;

                            dialogContext.openDialog(L10n.__("¿Seguro que deseas guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), accepted => {
                                if (accepted) {
                                    setSavingInProgress(true);

                                    if (row) {
                                        unsavedAvailability.forEach((row) => {
                                            if (!row.endTimeEnabled) row.duration = null;
                                        });
                                        APIAdminRequest("update-availability", { data: unsavedAvailability }).then(() => {
                                            AdminService.GetAvailabilityForNegocio(negocioID).then((response) => {
                                                setEvents(response.negocio.events);
                                                setAvailabilityCalendar(response.calendar);
                                                setSavingInProgress(false);
                                                setUnsavedChanges(false);
                                                setOpenRowDetailsIndex(-1);
                                            });
                                        });
                                    } else {
                                        APIAdminRequest("create-availability", { data: newRowData, timeTable }).then(() => {
                                            AdminService.GetAvailabilityForNegocio(negocioID).then((response) => {
                                                setEvents(response.negocio.events);
                                                setAvailabilityCalendar(response.calendar);
                                                setSavingInProgress(false);
                                                setUnsavedChanges(false);
                                                setOpenNewRowDetailsIndex(-1);
                                            });
                                        });
                                    }
                                }
                            });
						}}>
						{!row ? L10n.__("Crear") : L10n.__("Guardar")}
					</button>
				</div>
			</div>
		);
	}

	function confirmUnsavedChanges() {
		return !unsavedChanges || confirm(L10n.__("Hay cambios sin guardar. ¿Quieres descartarlos?"));
	}

	const changeDateRef = useRef(() => {});
	const changeDate = useCallback(
		(date) => {
			setOpenNewRowDetailsIndex(-1);
			setOpenRowDetailsIndex(-1);
			setOpenRowEventIndex(-1);
			if (changeDateRef.current) changeDateRef.current(date);
		},
		[changeDateRef.current]
	);

	const getEvents = useCallback(() => {
		setAvailabilityCalendar({});
		setIsLoading(true);
		AdminService.GetAvailabilityForNegocio(negocioID).then((response) => {
			setNegocio(response.negocio);
			setEvents(response.negocio.events);
			setAvailabilityCalendar(response.calendar);
			setIsLoading(false);
		});
	}, [negocioID]);

	const openRowDetails = useCallback(
		(index, eventIndex) => {
			setOpenRowDetailsIndex(index);
			setOpenRowEventIndex(eventIndex);
		},
		[availabilityCalendar, selectedDates]
	);

	useEffect(() => {
		changeDate(null);
		getEvents();
	}, [getEvents]);

	useEffect(() => {
		if (openNewRowDetailsIndex != -1) {
			setUnsavedChanges(true);
		}
	}, [newRowData]);

	useEffect(() => {
		changeDate(null);
	}, []);

	let price = negocio.price;

	if (isLoading)
		return (
			<div className="inner">
				<div className="narrow-content">
					<LoadingIndicator />
				</div>
			</div>
		);

	return (
		<div className="inner">
			<div className="narrow-content">
				{savingInProgress && <LoadingIndicator />}
				{availabilityCalendar !== null && (
					<Calendar
						defaultValue={selectedDates[0] || null}
						months={window.innerWidth <= 992 ? 1 : 2}
						allowMultipleSelection={true}
						allowUnavailableSelection={true}
						changeDateRef={(ref) => (changeDateRef.current = ref)}
						allowPast={false}
						availabilityCalendar={availabilityCalendar}
						onSelectionChanged={(dates) => {
							const date = dates[0];
							if (date && selectedDates != dates) {
								setOpenNewRowDetailsIndex(-1);
								setOpenRowDetailsIndex(-1);
								setOpenRowEventIndex(-1);
								setSelectedDates(dates);
								openRowDetails(-1, -1);

								setTimeout(() => {
									$("body, html").animate({ scrollTop: $(".orders-list").offset().top - 170 }, 500);
								});
							}
						}}
					/>
				)}
			</div>
			{selectedDates != null && selectedDates.length > 0 && (
				<div className="orders-list auto-table">
					{events.map((event, eventIndex) => {
						if (event.price != -1) price = event.price;

						return (
							<div className="event-group" key={"event-group-" + eventIndex}>
								<h2 className="event-name">{event.nombre}</h2>
								{event.availability
									.filter((availability) => {
										return -1 != selectedDates.findIndex((date) => Helper.GetISODate(date) == availability.slot.split(" ")[0]);
									})
									.map((availability, availabilityIndex) => {
										if (availability.price != -1) price = availability.price;
										const isOpenRow = openRowDetailsIndex == availabilityIndex && openRowEventIndex == eventIndex;

										return (
											<div className="availability-row" key={"availability-row-" + availabilityIndex}>
												<div
													className={"availability-row-header" + (isOpenRow ? " active" : "")}
													onClick={(e) => {
														if (confirmUnsavedChanges()) {
															if (isOpenRow) {
																setOpenRowDetailsIndex(-1);
																setOpenRowEventIndex(-1);
																setUnsavedAvailability([]);
																setUnsavedChanges(false);
															} else {
																setTimeTable({ [availability.slot.split(" ")[0]]: [{ time: availability.slot.split(" ")[1].substring(0, 5), duration: availability.duration, endTimeEnabled: false }] });
																const menuExtras = JSON.parse(availability.extras_menu || event.extras_menu || negocio.preorder_menu);
																const newData = { ...newRowData };
																newData.menu_extras = menuExtras;
																setNewRowData(newData);
																setUnsavedAvailability([]);
																setOpenRowDetailsIndex(availabilityIndex);
																setOpenRowEventIndex(eventIndex);
																setOpenNewRowDetailsIndex(-1);
																setUnsavedChanges(false);
															}
														}
													}}>
													<div className="hora">{availability.slot.split(" ")[1].substring(0, 5)}</div>
													<div className="fecha">{Helper.FormatISODate(availability.slot.split(" ")[0])}</div>
													<div className="duracion">{availability.duration ? Helper.FormatDuration(availability.duration) : ""}</div>
													<div className="precio">{Helper.FormatAmount(price / 100, true, true)}</div>
													<div className="pax">
														{availability.pax} / {availability.pax_total}
													</div>
													<div className="expand-button">
														<MaterialIcon name={isOpenRow ? "expand_less" : "expand_more"} />
													</div>
												</div>
												{isOpenRow && renderAvailabilityDetails(availabilityIndex, availability, event)}
											</div>
										);
									})}
								<div className="availability-row">
									<div
										className={"availability-row-header crear-disponibilidad-button" + (openNewRowDetailsIndex == eventIndex ? " active" : "")}
										onClick={(e) => {
											if (confirmUnsavedChanges()) {
												if (openNewRowDetailsIndex == eventIndex) {
													setOpenNewRowDetailsIndex(-1);
													setUnsavedChanges(false);
												} else {
													const newTimeTable = {...timeTable};
													
													Object.keys(timeTable).forEach((date) => {
														if (!selectedDates.find((selectedDate) => Helper.GetISODate(selectedDate) == date)) {
															delete newTimeTable[date];
														}
													});
													
													selectedDates.forEach((date) => {
														if (!newTimeTable[Helper.GetISODate(date)]) newTimeTable[Helper.GetISODate(date)] = [{time: "00:00", duration: 0}];
													});

													setTimeTable(newTimeTable);

													const extrasMenu = event.extras_menu ? JSON.parse(event.extras_menu) : JSON.parse(negocio.preorder_menu);
													setOpenNewRowDetailsIndex(eventIndex);
													setNewRowData({
														menu_extras: newRowData.menu_extras || extrasMenu,
														pax: newRowData.pax || 1,
														price: newRowData.price || (event.price != -1 ? event.price : null) || (negocio.price != -1 ? negocio.price : 0),
														eventID: event.id
													});
													setOpenRowDetailsIndex(-1);
													setOpenRowEventIndex(-1);
													setUnsavedChanges(false);
												}
											}
										}}>
										<div>Crear disponibilidad</div>
										<div className="expand-button">
											<MaterialIcon name={openNewRowDetailsIndex == eventIndex ? "expand_less" : "expand_more"} />
										</div>
									</div>
									{openNewRowDetailsIndex == eventIndex && renderAvailabilityDetails(eventIndex)}
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}
