import Helper from "./Helper";
import CarouselEventos from "../components/CarouselEventos";
import L10n from "./Locale";

export default class SearchResultsProvider {
	_results = [];
	_elements = [];
	_searchParameters = { ciudad: "", fecha: "", pax: "" };
	_currentMainType = "";
	_currentOtherType = "";
	_mainTypeOffset = 0;
	_adminMode = false;
	_adminRefreshCallback = null;
	_lastTitleRendered = "";
	_onChangeTypeInfo = null;

	constructor(elementList, elementsPerRow = 5) {
		this.elementsPerRow = elementsPerRow;
		this.setElementList(elementList);
	}

	setOnChangeTypeInfo(cb) {
		this._onChangeTypeInfo = cb;
	}

	setAdminRefreshCallback(callback) {
		this._adminRefreshCallback = callback;
	}

	setAdminMode(adminMode) {
		this._adminMode = adminMode;
	}

	setElementList(elementList) {
		this._elements = [...elementList];
		this.search(this._searchParameters.ciudad, this._searchParameters.fecha, this._searchParameters.pax);
	}

	resetTitleRendering() {
		this._lastTitleRendered = "";
		return true;
	}

	search(ciudad = "", fecha = "", pax = 1) {
		this._results = this.getFilteredElements(ciudad, fecha, pax);
		this._searchParameters = { ciudad, fecha, pax };
		this.resetRenderingOffsets();

		return this._results;
	}

	getFilteredElements(ciudad, fecha, pax) {
		let results = [...this._elements];

		if (ciudad != "") {
			results = results.filter((negocio) => negocio.city == ciudad);
		}

		if (fecha != "") {
			results = results.filter((negocio) => negocio.events.reduce((acc, v) => acc.concat(v.availability), []).filter((row) => row.slot.indexOf(Helper.GetISODate(fecha)) != -1).length > 0);
		}

		const effectivePax = Math.max(1, pax);

		results = results.filter((negocio) => {
			return negocio.events.filter(event => event.loadAlways).length > 0 || negocio.events.reduce((acc, v) => acc.concat(v.availability), []).filter((row) => {
				return row?.pax >= effectivePax;
			}).length > 0;
		});
		results = results.filter(negocio => negocio.events.filter(event => event.enabled).length > 0);

		return results.sort((a, b) => {
            if (a.tipo.order < b.tipo.order && b.tipo.main == a.tipo.main) return -1;
            if (a.tipo.order > b.tipo.order && b.tipo.main == a.tipo.main) return 1;
            if (a.tipo.main && !b.tipo.main) return -1;
            if (!a.tipo.main && b.tipo.main) return 1;
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
        });
	}

	resetRenderingOffsets() {
		if (this._results.length == 0 || typeof this._results[0].tipo != "object") {
			return;
		}

		this._mainTypeOffset = 0;
		this._currentMainType = this._results.filter((result) => parseInt(result.tipo.main) == 1)[0]?.tipo?.id;
		this._currentOtherType = this._results.filter((result) => parseInt(result.tipo.main) != 1)[0]?.tipo?.id;
	}

	_getMainResultsForRendering(maxCount, consumeResults = false) {
		if (!this._currentMainType) {
			return [];
		}

		const currentTypeResults = this._results.filter((result) => parseInt(result.tipo.main) == 1 && result.tipo.id == this._currentMainType);
		const results = currentTypeResults.slice(this._mainTypeOffset, this._mainTypeOffset + maxCount).filter(negocio => negocio.events.filter(event => event.enabled).length > 0);

		if (consumeResults) {
			this._mainTypeOffset += maxCount;

			if (this._mainTypeOffset >= currentTypeResults.length) {
				const mainTypesList = this._results.map((result) => (parseInt(result.tipo.main) == 1 ? result.tipo.id : null)).filter((v, i, a) => v && a.indexOf(v) == i);
				const currentIndex = mainTypesList.indexOf(this._currentMainType);

				if (currentIndex >= 0 && currentIndex < mainTypesList.length) {
					this._currentMainType = mainTypesList[currentIndex + 1];
					this._mainTypeOffset = 0;
				} else {
					this._currentMainType = null;
				}
			}
		}
		
        return results;
	}

	moveType(type, offset) {
		if (this._onChangeTypeInfo) {
			const newType = { ...type };
			newType.previousOrder = newType.order;
			newType.order += offset;
			this._onChangeTypeInfo(newType);
		}
	}

	setTypeMain(type, main) {
		if (this._onChangeTypeInfo) {
			const newType = { ...type };
			newType.main = main;
			this._onChangeTypeInfo(newType);
		}
	}

	renderMainRow(maxCount = 10000) {
		const results = this._getMainResultsForRendering(maxCount, true);
		if (results.length == 0) {
			return null;
		}

		const currentTipo = results[0].tipo;
		const lastTitleRendered = this._lastTitleRendered;
		this._lastTitleRendered = currentTipo.plural;

		return (
			<div className="container">
				<div className="row">
					<div className="col">
						{lastTitleRendered != currentTipo.plural && <h2 className="titulo-bodegas">{Helper.UCFirst(currentTipo.plural)}</h2>}
						<div className="bodegas-container">
							<div className="carrusel-main-eventos" style={{ gridTemplateColumns: "repeat(" + this.elementsPerRow + ", " + this.elementsPerRow + "fr)", width: Helper.IsResponsive() ? "calc(" + results.length * 100 + "vw - " + results.length * 100 + "px)" : "auto" }}>
								{results.map((negocio, idx) => {
									let link = "/venue/" + negocio.slug;

									const skeleton = negocio.events.filter(e => e.loadAlways).length > 0;

									return (
									<div className={"carrusel-main-evento" + (skeleton ? " skeleton" : "")} key={idx} onClick={e => {
											if (skeleton) return;
												 location.href = link;
											 }}>
											 <div className="image-container" style={{ height: 200 + (5 - this.elementsPerRow) * 50, backgroundImage: skeleton ? null : "url(" + (negocio.image_url[0] == "/" ? negocio.image_url : "/static/images/eventos/" + negocio.image_url) + ")" }}>
												{negocio.iconos && (
													<div className="icons">
														{negocio.iconos.map((icono, idx) => {
															return (
																<div
																	style={{ position: "relative" }}
																	onClick={(e) => {
																		e.preventDefault();
																		e.stopPropagation();
																		return false;
																	}}
																	key={idx}>
                                                                    <img src={icono.image_url} className="icon-image" />
																	<div className="icon-tooltip">{icono.description}</div>
																</div>
															);
														})}
													</div>
												)}
											</div>
											<div className="details">
												<div className="name">{negocio.name}</div>
												<div className="city">{negocio.city}</div>
												{!skeleton && negocio.average_price != -1 && (
													<div className="minimum-price">
														<div>
															{L10n.__("Precio medio")} <span>{Helper.FormatAmount(Math.floor(negocio.average_price / 100))}</span>
														</div>
													</div>
												)}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	_getOtherResultsForRendering(consumeResults = false) {
		if (!this._currentOtherType) {
			return [];
		}

		const results = this._results.filter((result) => parseInt(result.tipo.main) != 1 && result.tipo.id == this._currentOtherType);

		if (consumeResults) {
			const otherTypesList = this._results.map((result) => (parseInt(result.tipo.main) != 1 ? result.tipo.id : null)).filter((v, i, a) => v && i == a.indexOf(v));
			const currentIndex = otherTypesList.indexOf(this._currentOtherType);

			if (currentIndex >= 0 && currentIndex < otherTypesList.length) {
				this._currentOtherType = otherTypesList[currentIndex + 1];
			} else {
				this._currentOtherType = null;
			}
		}

		return results;
	}

	renderOtherRow() {
		const results = this._getOtherResultsForRendering(true);

		if (results.length == 0) {
			return null;
		}

		const otherTipo = results[0].tipo;
		const lastTitleRendered = this._lastTitleRendered;
		this._lastTitleRendered = otherTipo.plural;

		return (
			<div className="banda-otros">
				<div className="container">
					<div className="info">
						{lastTitleRendered != otherTipo.plural && <h2 className="titulo-bodegas">{Helper.UCFirst(otherTipo.plural)}</h2>}
						<div>{otherTipo.description}</div>
					</div>
					<CarouselEventos adminMode={this._adminMode} elements={results} />
				</div>
			</div>
		);
	}

	renderDefaultRows(renderMainRows = true, renderOtherRows = true, limit = -1) {
		if (limit == 0) {
			return null;
		}

		return (
			<>
				{renderMainRows && this.renderMainRow(this.elementsPerRow)}
				{renderOtherRows && this.renderOtherRow()}
				{limit != 0 && ((renderMainRows && this._getMainResultsForRendering(this.elementsPerRow).length) || (renderOtherRows && this._getOtherResultsForRendering().length)) > 0 && this.renderDefaultRows(renderMainRows, renderOtherRows, limit - 1)}
			</>
		);
	}

	getResults() {
		return this._results;
	}
}
