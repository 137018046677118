import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import { PreorderSelectionContext } from "../context/PreorderSelectionContext";

import Helper from "../services/Helper.jsx";
import Icon from "./Icon.jsx";
import Session from "../services/Session.jsx";
import MaterialIcon from "./MaterialIcon";
import L10n from "../services/Locale";

export default function PreorderTicket(props) {
    const isResponsive = window.innerWidth <= 992;
	const preorderSelectionContext = useContext(PreorderSelectionContext);

	function renderSidebarTotals() {
		const price = props.event.availability.price != -1 ? props.event.availability.price : props.event.price != -1 ? props.event.price : props.event.negocio.price;

		if (props.page == "otros" && props.booking.preorder_selection && props.booking.preorder_selection.length > 0) {
			return (
				<>
					<div className="details-line" style={{ fontSize: "0.9em", marginTop: 20 }}>
						<span className="sidebar-quantity">{props.booking.pax}</span>
						{props.event.nombre} <span style={{ marginLeft: "auto" }}>{Helper.FormatAmount(((props.event.full_booking == 1 ? 1 : props.booking.pax) * price) / 100)}</span>
					</div>
					{props.booking.preorder_selection.map((item, idx) => {
						return (
							<div className="details-line" key={idx} style={{ fontSize: "0.9em" }}>
								<span className="sidebar-quantity">{item.quantity}</span>
								{item.name}
								<span style={{ marginLeft: "auto" }}>{Helper.FormatAmount((item.quantity * item.price) / 100)}</span>
							</div>
						);
					})}
				</>
			);
		}

		return null;
	}

	function renderExtrasSelection() {
		if (preorderSelectionContext.preorderSelection.length == 0) {
			return null;
		}

		return (
			<div className="preorder-selection-information">
				{preorderSelectionContext.preorderSelection.map((item, idx) => {
					if (item.quantity == 0) return null;

					return (
						<div className="item" key={"preorder-item-" + idx}>
							<div className="title">{!isResponsive && <span className="qty">{item.quantity} </span>}{item.name}{isResponsive && <span className="qty"> &times;{item.quantity}</span>}</div>
							<div className="details">
								<div>
									{Helper.FormatAmount((item.price * item.quantity) / 100)}
								</div>
							</div>
							<div
								className="delete-button"
								onClick={(e) => {
									const newPreorderSelection = [...preorderSelectionContext.preorderSelection];
									newPreorderSelection.splice(idx, 1);
									preorderSelectionContext.changeSelection(newPreorderSelection);
								}}>
								&times;
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	function renderOtherSelection() {
		if (preorderSelectionContext.preorderSelection.length == 0) {
			return null;
		}

		return (
			<div className="preorder-selection-information">
				{preorderSelectionContext.preorderSelection.map((item, idx) => {
					return (
						<div className="item" key={"preorder-item-" + idx}>
							<div className="title">
								{item.event_name} | {item.name}
							</div>
							<div className="details">
								<div>
									<MaterialIcon name="calendar_month" /> {Helper.FormatISODate(item.slot.split(" ")[0])}
								</div>
								<div>
									<MaterialIcon name="schedule" /> {item.slot.split(" ")[1].substring(0, 5)}
								</div>
								<div>
									<MaterialIcon name="account_circle" /> {item.quantity}
								</div>
								<div>
									<MaterialIcon name="credit_card" /> {Helper.FormatAmount((item.price * (item.full_booking ? 1 : item.quantity) + item.extras?.reduce((acc, extra) => acc + extra.price * extra.qty, 0)) / 100)}
								</div>
							</div>
							{item.extras?.length > 0 && (
								<div className="extras">
									<div className="extra-row">
										<span>{item.quantity}</span>
										<span>{item.event_name}</span>
										<span>{Helper.FormatAmount((item.price * (item.full_booking ? 1 : item.quantity)) / 100)}</span>
									</div>
									{item.extras.map((extra, extra_index) => {
										if (extra.qty == 0) return null;
										return (
											<div className="extra-row" key={"extra-row-" + extra_index}>
												<span>{extra.qty}</span> <span>{extra.name}</span> <span>{Helper.FormatAmount((extra.price * extra.qty) / 100)}</span>
											</div>
										);
									})}
								</div>
							)}
							<div
								className="delete-button"
								onClick={(e) => {
									const newPreorderSelection = [...preorderSelectionContext.preorderSelection];
									newPreorderSelection.splice(idx, 1);
									preorderSelectionContext.changeSelection(newPreorderSelection);
								}}>
								&times;
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	const price = props.event.availability.price != -1 ? props.event.availability.price : props.event.price != -1 ? props.event.price : props.event.negocio.price;
	const totalPrice = props.booking.preorder_selection?.reduce((p, v) => p + v.quantity * v.price, (props.event.full_booking ? 1 : props.booking.pax) * price) || (props.event.full_booking ? 1 : props.booking.pax) * price;

	return (
		<div className={"booking-information " + (props.page || "upselling")}>
			<div className="details-line availability-description">
				{props.event.nombre} | {props.event.negocio.name}
			</div>
			<div className="details-line">
				<MaterialIcon name="calendar_month" />
				{Helper.FormatISODate(props.booking.selected_date, Helper.DATE_VERBOSE_LONGEST).split(", ")[1]}
			</div>
			<div className="details-line">
				<MaterialIcon name="schedule" />
				{props.booking.selected_slot.substring(0, 5)}
				{Helper.RenderDurationEndTime(props.booking.selected_slot.substring(0, 5), props.event.availability.duration, " a ")}
			</div>
			<div className="details-line">
				<MaterialIcon name="account_circle" />
				{props.booking.pax} {props.booking.pax == 1 ? L10n.__("persona") : L10n.__("personas")}
			</div>
			<div className="details-line">
				<MaterialIcon name="credit_card" />
				{Helper.FormatAmount(totalPrice / 100)}
			</div>
			{props.promo_title && (
				<div>
					<Icon name="promo" />
					{props.promo_title}
				</div>
			)}
			{renderSidebarTotals()}
			<div className="change-order-link">
				<Link to={"/venue/" + props.event.negocio.slug}>{L10n.__("¿Quieres modificar tu reserva?")}</Link>
			</div>
			{props.page == "otros" ? renderOtherSelection() : renderExtrasSelection()}
		</div>
	);
}
