import { useState } from "react";

import MaterialIcon from "./MaterialIcon";

import "../../css/Table.css";

export default function Table(props) {
    const [ expandedRowIndex, setExpandedRowIndex ] = useState(-1);

    const isResponsive = window.innerWidth <= 992;

    let totalWidth = 0;
    if (isResponsive && props.responsiveWidths) {
        totalWidth = props.responsiveWidths.reduce((acc, v) => v == -1 ? acc : acc + v, 0);
    } else if (props.widths) {
        totalWidth = props.widths.reduce((acc, v) => v == -1 ? acc : acc + v, 0);
    }

    return <div className="table-component">
        {props.header && props.header.length > 0 && <div className="table-header">
            {props.header.map((caption, idx) => (!isResponsive || !props.responsiveState || (props.responsiveState && props.responsiveState[idx])) && <span style={{ textAlign: props.alignment ? props.alignment[idx] : null, width: props.widths ? 100 * ((isResponsive && props.responsiveWidths ? props.responsiveWidths[idx] : props.widths[idx])/totalWidth) + "%" : null}} key={"header-"+idx}>{caption}</span>)}
            {props.items.findIndex(item => item.data) != -1 && <span style={{ width: 44 }}>&nbsp;</span>}
        </div>}
        {props.items && props.items.length > 0 && props.items.map((item, idx) => {
            return <div className="table-row-container" key={"row-"+idx}>
                <div className={"table-row" + (expandedRowIndex == idx ? " expanded" : "")}>
                    {item.columns.map((column, idx) => (!isResponsive || !props.responsiveState || (props.responsiveState && props.responsiveState[idx])) && <span style={{ textAlign: props.alignment ? props.alignment[idx] : null, width: (props.widths && (isResponsive && props.responsiveWidths ? props.responsiveWidths[idx] : props.widths[idx]) != -1) ? 100 * ((isResponsive && props.responsiveWidths ? props.responsiveWidths[idx] : props.widths[idx])/totalWidth) + "%" : null, flex: (props.widths && (isResponsive && props.responsiveWidths ? props.responsiveWidths[idx] : props.widths[idx]) == -1) ? 1 : null}} className={"table-column" + (props.alignment ? " " + props.alignment[idx] : " left")} key={"column-"+idx}>{column}</span>)}
                    {props.renderDetails && item.data && <div className="expand-button no-auto-width" onClick={() => {
                        if (expandedRowIndex == idx) {
                            if (!props.willCloseDetails) {
                                setExpandedRowIndex(-1);
                            } else {
                                props.willCloseDetails(answer => {
                                    if (answer) {
                                        setExpandedRowIndex(-1);
                                    }
                                });
                            }
                        } else {
                            if (expandedRowIndex == -1) {
                                if (props.onOpenDetails) props.onOpenDetails(item.data, idx);
                                setExpandedRowIndex(idx);
                            } else if (!props.willCloseDetails) {
                                if (props.onOpenDetails) props.onOpenDetails(item.data, idx);
                                setExpandedRowIndex(idx);
                            } else {
                                props.willCloseDetails(answer => {
                                    if (answer) {
                                        if (props.onOpenDetails) props.onOpenDetails(item.data, idx);
                                        setExpandedRowIndex(idx);
                                    }
                                });
                            }
                        }
                    }}><MaterialIcon name={expandedRowIndex == idx ? "expand_less" : "expand_more"} /></div>}
                </div>
                {expandedRowIndex == idx && props.renderDetails && <div className="row-details" key={JSON.stringify(item.data)}>{props.renderDetails(item.data, idx)}</div>}
            </div>;
        })}
    </div>;
}