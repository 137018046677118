import { useState, useEffect } from "react";

export default function FormToggle(props) {
	const [value, setValue] = useState(props.defaultValue == true || false);
	const onChange = props.onChange;

	const active = props.checked || value;

	return <div onClick={e => {
		e.preventDefault();
		if (props.disabled) return;
		if (props.onChange) onChange(!active);
		setValue(!active);
	}} data-tooltip={props.tooltip} className={"form-toggle" + (props.className ? " " + props.className : "") + (props.tooltip ? " has-tooltip" : "") + (props.large ? " large" : "") + (active === true ? " active" : "") + (props.disabled === true ? " disabled" : "")} />
}
