import { useEffect, useState, useCallback, useContext } from "react";
import AdminService from "../services/Admin.js";
import LoadingIndicator from "./LoadingIndicator";
import AdminBar from "./AdminBar";
import FormToggle from "./FormToggle.jsx";
import Table from "./Table.jsx";
import { APIAdminRequest } from "../services/API.js";
import L10n from "../services/Locale.jsx";
import UI from "../services/UI.js";
import {DialogContext} from "../context/DialogContext.jsx";

export default function AdminPermisos(props) {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [permissions, setPermissions] = useState([]);
	const [users, setUsers] = useState([]);
    const [modifiedUser, setModifiedUser] = useState({});

	const dialogContext = useContext(DialogContext);

    useEffect(() => {
		setLoading(true);
		AdminService.GetUsers().then(users => {
			AdminService.GetPermissions().then((permissions) => {
				setUsers(users.filter(user => user.is_partner == 1 && user.is_superuser == 0));
				setPermissions(permissions);
				setLoading(false);
			});
		});
	}, []);

	const save = useCallback(() => {
		setSaving(true);
		APIAdminRequest("set-user-permissions", { user_id: modifiedUser.id, data: modifiedUser.permissions }).then(response => {
			UI.ShowNotification(L10n.__("Cambios guardados con éxito"));
			setSaving(false);
		});
	}, [modifiedUser]);

    if (loading) return <LoadingIndicator />;

    return (
	<div className="admin-page admin-permisos">
        <AdminBar />
        <Table
			header={[ L10n.__("Nombre"), L10n.__("Email"), "" ]}
			widths={[ 2, 6, 2 ]}
			alignment={[ "left", "left" ]}
			items={users.map(usuario => ({ data: usuario, columns: [
                usuario.name + " " + usuario.surname,
                usuario.login
            ]}))}
            onOpenDetails={row => {
				setModifiedUser({...row});
            }}
			renderDetails={(row, idx) => <>
				<div>
                    {permissions.map((pair, pairIdx) => <div key={pair[0] + "-" + pairIdx}>
						<span>{pair[1]}</span> <FormToggle type="checkbox" defaultValue={modifiedUser.permissions[pair[0]]} onChange={value => {
							const n = { ...modifiedUser };
							if (!n.permissions || n.permissions.length && n.permissions.length == 0) n.permissions = {};
							n.permissions[pair[0]] = value;
							setModifiedUser(n);
						}} />
					</div>)}
				</div>
				<div>
					<a className={"btn btn-brown boton-guardar" + (saving ? " disabled" : "")} onClick={e => {
						e.preventDefault();
                        if (saving) return;
						
						dialogContext.openDialog(L10n.__("¿Seguro que deseas guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), accepted => {
							if (accepted) {
								save();
							}
						});
					}}>{L10n.__("Guardar")}</a>
				</div>
			</>}
		/>
	</div>
    );
}
