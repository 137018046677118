import { APIRequest } from "./API";
import Session from "./Session";

export default class Orders {
    static getOrderDetails(booking_information) {
        return new Promise((resolve, reject) => {
            booking_information.current_order_id = parseInt(sessionStorage.getItem('current_order_id'));
            let order_details = JSON.parse(JSON.stringify(booking_information));
            
            order_details["type"] = Session.GetBookingData("preorder_path");
            order_details["selected_availability_id"] = Session.GetBookingData("selected_availability_id");

            if (order_details.preorder_selection) {
                order_details.preorder_selection = JSON.stringify(order_details.preorder_selection);
            }
            if (order_details.extra_events) {
                order_details.extra_events = JSON.stringify(order_details.extra_events);
            }
            
            APIRequest('payment/get-details', order_details).then(response => {
                resolve(response);
            });
        });
    }

    static async CompleteOrder(order_id) {
        const response = await APIRequest('payment/complete-order', { order_id });
        return response;
    }

    static async UpdateOrderPreferences(order_id, comments, wants_newsletter) {
        await APIRequest('payment/update-order-preferences', { order_id, comments, wants_newsletter: wants_newsletter ? 1 : 0 })
    }

    static async GetEligiblePromos() {
        const response = await APIRequest('booking/promos', { booking_data: JSON.stringify(Session.GetBookingData()) });
        return response.data;
    }

    static async GetOrdersList() {
        const response = await APIRequest('users/get-orders');
        return response.data;
    }

    static async PartnerChangeOrderExtrasQuantity(orderID, extrasIndex, newQuantity) {
        const response = await APIRequest('partners/change-extras-quantity', {
            order_id: orderID,
            extras_index: extrasIndex,
            new_quantity: newQuantity
        });
        return response.data;
    }

    static async PartnerDeleteOrder(orderID) {
        const response = await APIRequest("partners/delete-order", { order_id: orderID });
        return response.status;
    }

    static async PartnerAddExtrasToOrder(orderID, additionalExtras) {
        const response = await APIRequest("partners/add-extras-to-order", { order_id: orderID, extras: JSON.stringify(additionalExtras) });
        return response.status;
    }
}
