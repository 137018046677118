import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Events from '../services/Events';
import Session from '../services/Session';
import L10n from '../services/Locale';
import $ from 'jquery';

export default class GraciasEncuesta extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $('header').addClass('simple');
    }

    componentWillUnmount() {
        $('header').removeClass('simple');
    }

    render() {
        return (
            <div className="gracias-encuesta">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>{L10n.__("Gracias por compartir tu experiencia")}</h1>
                            <h2>{L10n.__("Tu opinión nos ayuda a mejorar.")}</h2>

                            <div className="buttons">
                                <Link to="/" className="btn btn-brown">{L10n.__("Volver al inicio")}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
