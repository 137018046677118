import React, { useState, useEffect} from 'react';
import L10n from '../services/Locale';
import Helper from '../services/Helper';
import AdminService from '../services/Admin';
import UI from '../services/UI';

export default function WidgetAttachmentLimits(props) {
	const [maxUploadSize, setmaxUploadSize] = useState(-1);
	const [elementID, _] = useState("widgetattachmentlimits" + Math.floor(Math.random() * 1000000));
	const uploadedFileSize = props.uploadedFileSize;
	const position = props.position || "top-left";

	useEffect(() => {
		if (maxUploadSize != -1 && uploadedFileSize > maxUploadSize) {
			UI.ShowNotification(L10n.__("El tamaño del archivo supera el máximo permitido."), UI.NOTIFICATION_TYPE_ERROR);
		}
	}, [ uploadedFileSize ]);

	useEffect(() => {
		AdminService.GetMaxUploadSize().then(size => setmaxUploadSize(size));
		
		setTimeout(() => {
			const $element = $("#"+elementID);
			const $parent = $("#"+elementID).parent();
			if (props.alwaysShow || ($element.width() < $parent.width() && $element.height() < $parent.height())) {
				$element.css("opacity", 1);
			} else {
				$element.css("opacity", 0);
			}
		}, 500);
	}, []);

	let top = 10;
	let left = 10;
	let right = 10;
	let bottom = 10;

	if (props.positionOffset) {
		if (props.positionOffset[0]) {
			left = "calc(" + left + "px " + (props.positionOffset[0][0] == "-" ? "-" : "+") + " " + props.positionOffset[0].replace("-", "") + ")";
			right = "calc(" + right + "px " + (props.positionOffset[0][0] == "-" ? "-" : "+") + " " + props.positionOffset[0].replace("-", "") + ")";
		}

		if (props.positionOffset[1]) {
			top = "calc(" + top + "px " + (props.positionOffset[1][0] == "-" ? "-" : "+") + " " + props.positionOffset[1].replace("-", "") + ")";
			bottom = "calc(" + bottom + "px " + (props.positionOffset[1][0] == "-" ? "-" : "+") + " " + props.positionOffset[1].replace("-", "") + ")";
		}
	}

	const style = {...props.style} || {};
	if(typeof style.left === "undefined") style.left = position?.split("-")[1] == "left" ? left : "";
	if(typeof style.top === "undefined") style.top = position?.split("-")[0] == "top" ? top : "";
	if(typeof style.right === "undefined") style.right = position?.split("-")[1] == "right" ? right : "";
	if(typeof style.bottom === "undefined") style.bottom = position?.split("-")[0] == "bottom" ? bottom : "";

	if (props.compact) {
		return <div className="widget-attachment-limits compact" id={elementID} style={style}>
			<div>{props.recommendedWidth || 0} &times; {props.recommendedHeight || 0}</div>
			<div className="separator" />
			<div>{props.fileFormats?.join(", ").toUpperCase()}</div>
			<div className="separator" />
			<div>{Helper.FormatSize(props.maxFileSize || maxUploadSize)} {L10n.__("máx.")}</div>
		</div>;
	} else {
		return <div className="widget-attachment-limits" id={elementID} style={style}>
			<div>{L10n.__("Proporción:")} {props.recommendedWidth || 0} &times; {props.recommendedHeight || 0}</div>
			<div>{L10n.__("Formato:") + " " + props.fileFormats?.join(", ").toUpperCase()}</div>
			<div>{L10n.__("Tamaño:")} {Helper.FormatSize(props.maxFileSize || maxUploadSize)} {L10n.__("máx.")}</div>
		</div>;
	}
}
