import { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import FormInput from './FormInput.jsx';
import Session from './../services/Session';
import $ from 'jquery';
import L10n from '../services/Locale.jsx';

import MaterialIcon from './MaterialIcon.jsx';
import Orders from '../services/Orders.jsx';
import LoadingIndicator from './LoadingIndicator.jsx';
import Helper from '../services/Helper.jsx';
import Icon from './Icon.jsx';
import TabMenu from './TabMenu.jsx';
import AsyncButton from './AsyncButton.jsx';
import UI from "../services/UI";
import Modal from "./Modal";
import { SessionContext } from '../context/SessionContext.jsx';

const ORDERS_PER_PAGE = 16;

export default function MiCuenta(props) {
    const [promoCodes, setPromoCodes] = useState([]);
    const [currentOrder, setCurrentOrder] = useState(Session.GetBookingData());
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [orders, setOrders] = useState([]);
    const [name, setName] = useState();
    const [password, setPassword] = useState();
    const [surname, setSurname] = useState();
    const [phone, setPhone] = useState();
    const [login, setLogin] = useState();
    
    const extrasPopupRef = useRef();
    const changePasswordRef = useRef();
    const saveProfileRef = useRef();

    const sessionContext = useContext(SessionContext);

    useEffect(() => {
        Orders.GetOrdersList().then(orders => {
            setOrders(orders);
            setTotalPages(Math.ceil(orders.length / ORDERS_PER_PAGE));
            setCurrentPage(0);
        });

        Session.GetPromoCodes().then(promoCodes => {
            setPromoCodes(promoCodes);
        });
    }, []);

    function renderTabCambiarContrasena() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <form autoComplete="off"
                        className="change-password-form"
                        onSubmit={onSubmitContrasena}>
                             <FormInput required
                                type="password"
                                id="password"
                                label={L10n.__("Contraseña")}
                                onValidate={value => {
                                    if(value.length < 8) return L10n.__("La contraseña debe tener al menos 8 caracteres. Una contraseña fuerte debe contener letras, números y símbolos.");
                                }}
                                onChange={value => {
                                    setPassword(value);
                                }}
                            />
                            <FormInput required
                                type="password"
                                id="password2"
                                label={L10n.__("Repetir contraseña")}
                                onValidate={value => {
                                    if(value.length && password != value) return L10n.__("Las contraseñas no coinciden.");
                                }}
                            />
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <AsyncButton className="btn btn-brown" style={{ marginTop: 25 }} ref={ref => changePasswordRef.current = ref} onClick={onSubmitContrasena} caption={L10n.__("Cambiar contraseña")} />
                    </div>
                </div>
            </div>
        );
    }

    function renderTabUsuario() {
        return (
            <div className="row">
                <div className="col-md">
                    <div className="row">
                        <div className="col-md">
                            <form autoComplete="off"
                            onSubmit={onSubmitUsuario}>
                                <FormInput required type="text" id="nombre" label={L10n.__("Nombre")} initialValue={sessionContext.session.name || ""}
                                onChange={value => {
                                    setName(value);
                                }} />
                                <FormInput required type="text" id="apellidos" label={L10n.__("Apellidos")} initialValue={sessionContext.session.surname || ""}
                                onChange={value => {
                                    setSurname(value);
                                }} />
                                <FormInput type="phone" id="telefono" label={L10n.__("Teléfono (opcional)")} initialValue={sessionContext.session.phone || ""}
                                onChange={value => {
                                    setPhone(value || "");
                                }} />
                                <FormInput type="email" id="email" label={L10n.__("Email")} initialValue={sessionContext.session.login || ""}
                                    onValidate={value => {
                                        // eslint-disable-next-line no-control-regex
                                        if(!value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) return 'Por favor introduce una dirección de email válida.';
                                    }}
                                    onChange={value => {
                                        setLogin(value);
                                    }}
                                />
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <AsyncButton className="btn btn-brown" style={{ marginTop: 25 }} ref={ref => saveProfileRef.current = ref} onClick={onSubmitUsuario} caption={L10n.__("Guardar")} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderOrderGrid(filter, showOrderAgainButton=true, showCallToAction=false, buttonCaption=L10n.__("REPETIR RESERVA"), page=0) {
        const unfilteredOrders = [...orders];
        let orderInProgress = false;

        if (currentOrder && currentOrder.selected_event) {
            unfilteredOrders.forEach((order, idx) => {
                if (order.payment_status === 0 && order.event.id === currentOrder.selected_event_id && order.booked_date === currentOrder.selected_full_date) {
                    orderInProgress = true;
                }
            });
        }

        const filteredOrders = unfilteredOrders.filter(filter);

		return (
			<>
				<div className="order-grid" style={(Helper.IsResponsive() && filteredOrders.length) ? {width: filteredOrders.length * window.innerWidth * 0.8} : null}>
					{ filteredOrders.slice(currentPage * ORDERS_PER_PAGE, currentPage * ORDERS_PER_PAGE + ORDERS_PER_PAGE).map((order, idx) => {
						return renderOrder(order, idx, showOrderAgainButton, buttonCaption);
					}) }
					{filteredOrders.length > 0 && <p style={{marginBottom: 25 }} />}
					{filteredOrders.length === 0 && <p style={{marginBottom: 25, gridColumnEnd: 4, gridColumnStart: 1 }}>No hay ninguna reserva.{ showCallToAction && <span>&nbsp;<Link to={orderInProgress ? "/pago" : "/"}>Reservar ahora</Link>.</span> }</p>}
				</div>
				{totalPages > 1 && <div className="pagination">
					{Array(totalPages).fill(0).map((v,i) => i).map(idx => {
						return <a href="#" onClick={e => {
							e.preventDefault();
                            setCurrentPage(idx);
						}} className={"page-button" + (currentPage == idx ? " current" : "")}>{idx+1}</a>
					})}
				</div>}
			</>
		);
    }

    function renderOrder(order, idx, showOrderAgainButton=true, buttonCaption=L10n.__("REPETIR RESERVA")) {
        return (
            <div key={idx} className={["order-item", order.in_progress ? "in-progress" : null].join(" ")} style={{
                marginRight: 5,
                paddingBottom: showOrderAgainButton ? 1 : 10
            }} onClick={e => {
                if (order.in_progress) {
                    e.preventDefault();
                    window.location.href = '/pago';
                }
            }}>
                {order.in_progress && <span>{L10n.__("Reserva pendiente")}</span>}
                <div className="restaurant-image" style={{backgroundImage: 'url(/static/images/eventos/' + (order.event.image_url || order.event.event_image_url) + ')'}}>{order.preorder_selection.length > 0 && <a className="extras-button btn btn-rounded" href="#" onClick={e => {
                    e.preventDefault();
                    
                    extrasPopupRef.current.open();
                    setTimeout(() => {
                        extrasPopupRef.current.setState({
                            order,
                            custom_title: L10n.__("Extras ") + order.event.name
                        });
                    });
                }}>{L10n.__("Ver extras")}</a>}</div>
                <div className="restaurant-name">{order.event.name}</div>
                <div className="info-line"><MaterialIcon name="calendar_month" />{Helper.FormatISODate(order.booked_date.split(' ')[0])}</div>
                <div className="info-line"><MaterialIcon name="schedule" />{order.booked_slot}</div>
                <div className="info-line"><MaterialIcon name="account_circle" />{order.pax} {order.pax === 1 ? L10n.__("persona") : L10n.__("personas")}</div>
                {(order.promo && order.promo.title) ?
                    <div className="info-line"><Icon name="promo" />{order.promo.title}</div> :
                    <div className="info-line">&nbsp;</div>
                }
                { showOrderAgainButton && !order.in_progress && <a className="btn btn-brown rounded" onClick={() => {
                    onRepeatOrder(order)
                }}>{buttonCaption}</a>}
                { showOrderAgainButton && order.in_progress && <Link className="btn btn-brown rounded" to="/pago">{L10n.__("COMPLETAR RESERVA")}</Link>}
            </div>
        );
    }

    function onRepeatOrder(order) {
        Session.SetBookingData("selected_restaurant", order.event);
        Session.SetBookingData("selected_restaurant_id", order.event.id);
        Session.SetBookingData("pax", order.pax);
        Session.SetBookingData("selected_full_date", null);
        Session.SetBookingData("promo_id", null);
        Session.SetBookingData("selected_slot", null);
        Session.SetBookingData("selected_full_slot", null);
        Session.SetBookingData("current_order_id", null);
        window.location.href = "/venue/" + order.event.slug;
    }

    function onSubmitUsuario(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        Session.SaveProfileData({
            name: name || sessionContext.session.name || "",
            surname: surname || sessionContext.session.surname || "",
            phone: phone || sessionContext.session.phone || "",
            login: login || sessionContext.session.login || ""
        }).then(result => {
            if (result.status) {
                UI.ShowNotification(result.data);
                saveProfileRef.current.reset();
            } else {
                UI.ShowNotification(result.data, UI.NOTIFICATION_TYPE_ERROR);
                saveProfileRef.current.reset(false);
            }
        });
    }

    function onSubmitContrasena(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        
        if (!password) {
            UI.ShowNotification(L10n.__("Por favor, introduce una contraseña"), UI.NOTIFICATION_TYPE_ERROR);
            changePasswordRef.current.reset();
            return;
        }

        if($('.form-input.not-validated').length === 0) {
            Session.DirectPasswordChange(password).then(result => {
                changePasswordRef.current.reset();
                UI.ShowNotification(result ? L10n.__("Contraseña cambiada") : L10n.__("No se ha podido cambiar la contraseña"), result ? null : UI.NOTIFICATION_TYPE_ERROR);
            });
        } else {
            changePasswordRef.current.reset();
        }
    }
    
    if (!sessionContext.session) {
        Session.RequireLogin();
        return null;
    }
    if (!orders) return <LoadingIndicator />;

    let orderInProgress = null;

    orders.forEach((order, idx) => {
        order.bookedDateObject = Helper.CreateDateCompatible(order.booked_date);
        order.bookedDateObject.setHours(order.booked_date.split(" ")[1].split(":")[0], order.booked_date.split(" ")[1].split(":")[1], order.booked_date.split(" ")[1].split(":")[2]);
        if (parseInt(order.payment_status) === 0 && order.event.id === currentOrder.selected_event_id && order.booked_date === currentOrder.selected_full_date) {
            orderInProgress = order;
            orderInProgress.in_progress = true;
        }
    });

    return (
        <div className="mi-cuenta">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>{L10n.__("Mi cuenta")}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col upper-tab-menu-container">
                        <TabMenu
                        tabs={[
                            {
                                caption: L10n.__("Usuario"),
                                render: renderTabUsuario,
                                onEnter: () => {
                                    $("#nombre").val(sessionContext.session.name || "");
                                    $("#apellidos").val(sessionContext.session.surname || "");
                                    $("#telefono").val(sessionContext.session.phone || "");
                                    $("#email").val(sessionContext.session.login || "");
                                }
                            },
                            {
                                caption: L10n.__("Cambiar contraseña"),
                                render: renderTabCambiarContrasena,
                                onEnter: () => {
                                    $("#password, #password2").val("");
                                }
                            },
                            {
                                caption: L10n.__("Cerrar sesión"),
                                onClick: () => {
                                    Session.Logout().then(() => {
                                        window.location.href = '/';
                                    });
                                }
                            }
                        ]}
                        />
                    </div>
                    { promoCodes.length > 0 &&
                    <div className="col-lg col-sm-12">
                        <h3>{L10n.__("Códigos descuento")}</h3>
                        {promoCodes.map((promoCode, idx) => {
                            return (
                                <div className="promo-code-row" key={idx}>
                                    <div className="promo-code-image-container" style={{ backgroundImage: "url(/static/images/promos/" + promoCode.image + ")" }} />
                                    <div>
                                        <div className="promo-code-title">{promoCode.title}</div>
                                        <div className="promo-code-description">{promoCode.description}</div>
                                        <div className="promo-code-code">{L10n.__("Código:")} {promoCode.code}</div>
                                        <div className="promo-code-description">{L10n.__("Caduca")} {Helper.FormatISODate(promoCode.expiration_date, 2)}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div> }
                    { promoCodes.length === 0 &&
                    <div className="col-lg col-sm-12 no-promos">
                        <h3>{L10n.__("Códigos descuento")}</h3>
                        <div className="promo-code-row">
                            <div className="promo-code-image-container" />
                            <div>
                                <div className="promo-code-title">{L10n.__("No hay descuentos activos")}</div>
                                <div className="promo-code-description"><span className="placeholder-bar" style={{width: "40%"}} /></div>
                                <div className="promo-code-code"><span className="placeholder-bar" style={{width: "70%"}} /></div>
                                <div className="promo-code-description"><span className="placeholder-bar" style={{width: "60%"}} /></div>
                            </div>
                        </div>
                    </div> }
                    { orderInProgress &&
                    <div className="col-lg-3 col-sm-12">
                        <div className="current-order-widget">
                            {renderOrder(orderInProgress, 0)}
                        </div>
                    </div> }
                    { !orderInProgress && <div className="col-lg-3 col-sm-12">
                        <div className="order-item pending-order-placeholder" style={{
                            paddingBottom: 1
                        }} onClick={e => {}}>
                            <div className="restaurant-image" style={{backgroundColor: '#dedede77'}}></div>
                            <div className="restaurant-name"></div>
                            <div className="info-line"><MaterialIcon name="calendar_month" /><span className="placeholder-bar" style={{width: "20%"}} /></div>
                            <div className="info-line"><MaterialIcon name="schedule" /><span className="placeholder-bar" style={{width: "40%"}} /></div>
                            <div className="info-line"><MaterialIcon name="account_circle" /><span className="placeholder-bar" style={{width: "18%"}} /></div>
                            <Link className="btn btn-brown rounded" to="/">{L10n.__("RESERVAR AHORA")}</Link>
                        </div>
                    </div> }
                </div>
                <div className="row">
                    <div className="col">
                        <h2>{L10n.__("Reservas")}</h2>
                    </div>
                </div>
                <TabMenu
                className="tab-menu-reservas"
                tabs={[
                    {
                        caption: L10n.__("Próximas"),
                        render: () => {
                            return renderOrderGrid(order => parseInt(order.payment_status) === 1 && order.bookedDateObject > new Date(), false, true, L10n.__("RESERVAR AHORA"), currentPage);
                        }
                    },
                    {
                        caption: L10n.__("Pasadas"),
                        render: () => {
                            return renderOrderGrid(order => parseInt(order.payment_status) === 1 && order.bookedDateObject <= new Date(), true, false, L10n.__("RESERVAR AHORA"), currentPage);
                        }
                    },
                    {
                        caption: L10n.__("Canceladas"),
                        render: () => {
                            return renderOrderGrid(order => parseInt(order.payment_status) === 0 && !order.in_progress, true, false, L10n.__("RESERVAR AHORA"), currentPage);
                        }
                    }
                ]}
                />
            </div>
            <Modal ref={ref => extrasPopupRef.current = ref} className="extras-modal" renderContent={modal => {
                return modal.state.order?.preorder_selection.map((item, idx) => {
                    return <div className="item" key={idx}>
                        <div className="image" style={{ backgroundImage: "url(" + item.image_url + ")" }}>
                            <div className="quantity">{item.quantity}</div>
                        </div>
                        <div className="details">
                            <div className="name">{item.name}</div>
                            <div className="description">{item.description}</div>
                        </div>
                    </div>;
                })
            }} />
        </div>
    );
};