import React, { useEffect, useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import ContentHelper from '../services/ContentHelper';

export async function GetJSON(slug) {
    const response = await ContentHelper.GetString(slug);
    return JSON.parse(response);
}

export function Content({slug, onEmpty, onLoad, containerClassName}) {
    const [empty, setEmpty] = useState(true);
    const [contents, setContents] = useState(null);

    useEffect(() =>{
        ContentHelper.GetString(slug).then(value => {
            
            if(value == "") {
                if (!empty) {
                    setEmpty(true);
                }

                if (onEmpty) {
                    onEmpty();
                }
            } else {
                if (onLoad) {
                    onLoad(value);
                }
                setEmpty(false);
                setContents(HTMLReactParser(value || ""));
            }
        });
    }, [slug, empty, onEmpty, onLoad]);

    if (empty) {
        return null;
    }

    if (containerClassName) {
        return <div className={containerClassName}>{contents}</div>;
    }

    if (contents) {
        return [contents];
    }

    return (
        <span id={"cms-contents-" + slug} className="cms-content-placeholder"></span>
    );
}

export function ContentImage({alt, slug, className, containerClassName, onLoad}) {
    const [empty, setEmpty] = useState(true);
    const [src, setSrc] = useState(null);

    useEffect(() => {
        ContentHelper.GetString(slug).then(value => {
            if (value != "") {
                if (empty) {
                    setEmpty(false);
                }

                if (onLoad) {
                    onLoad(value);
                }

                setSrc(value);
            } else if(!empty) {
                setEmpty(true);
            }
        });
    }, [slug, empty, onLoad]);

    if (empty || !src) {
        return null;
    }

    if (containerClassName) {
        return (
            <div className={containerClassName}>
            <img alt={alt} className={className} src={src[0] == "/" ? src : "/static/images/" + src} />
            </div>
        );
    }

    return (
        <img alt={alt} className={className} src={src[0] == "/" ? src : "/static/images/" + src} />
    );
}
