import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Events from "../services/Events";
import LoadingIndicator from "./LoadingIndicator";
import ImageUpload from "./ImageUpload";
import TextEditor from "./TextEditor";
import JSONEditor from "./JSONEditor";
import Field from "./Field";
import AdminBar from "./AdminBar";
import Bloque from "./AdminBloque";
import MaterialIcon from "./MaterialIcon";
import InputSelect from "./InputSelect";
import FormToggle from "./FormToggle";
import { StarMeter } from "./Encuesta.jsx";
import BarChart from "./estadisticas/BarChart";

import AdminService from "../services/Admin";
import { APIAdminRequest } from "../services/API";
import Helper from "../services/Helper";
import AccordionTab from "./AccordionTab";
import { DialogContext } from "../context/DialogContext";
import L10n from "../services/Locale";
import UI from "../services/UI";

import "../../css/admin-editar-evento.css";
import { DaySelect } from "./AdminWidgetHorario";

export default function AdminEditarEvento(props) {
	const [evento, setEvento] = useState(null);
	const [negocio, setNegocio] = useState(null);
	const dialogContext = useContext(DialogContext);

	const [ selectedDays, setSelectedDays ] = useState([0]);
	const [ timeTable, setTimeTable ] = useState(null);
	const [ validationErrors, setValidationErrors ] = useState({});
	const [ programarEnabled, setProgramarEnabled ] = useState(null);
    const [surveyData, setSurveyData] = useState(null);

	const [statTypes, setStatsTypes] = useState();
	const [kpis, setKpis] = useState();
	const [ordersPerDay, setOrdersPerDay] = useState();

	const eventoID = props.eventID || 0;
	const negocioID = props.negocioID || 0;

	useEffect(() => {
		if (!negocio) return () => {};

		if (eventoID == 0) {
			setEvento({
				image_url: "",
				full_booking: 0,
				disponibilidad_multiple: 0,
				offers_upselling: 0,
                offers_preorder: 0,
				nombre: "",
				price: negocio.price,
				duration: 0,
				address: negocio.address,
				description: "",
                extras_menu: {},
                negocio_id: negocioID,
                enabled: true
			});

            setProgramarEnabled(true);
            setTimeTable([[], [], [], [], [], [], []]);
		} else {
			APIAdminRequest("get-event", { id: eventoID, locale: L10n.GetLanguage() }).then((response) => {
                if (typeof response.data.extras_menu === "string") {
                    response.data.extras_menu = JSON.parse(response.data.extras_menu || "{}") || {};
                }
				setEvento(response.data);
				setProgramarEnabled(response.data.automatic_availability);
				setTimeTable(response.data.timetable || [[], [], [], [], [], [], []]);
			});
            
            APIAdminRequest("surveys-get-event-data", { event_id: eventoID }).then(response => {
                setSurveyData(response.data);
            });


			if (eventoID > 0) {
				Promise.all([
					APIAdminRequest("stats-get-types"),
					APIAdminRequest("stats-get-evento-kpi", { evento_id: eventoID }),
					APIAdminRequest("stats-get-evento-orders-per-day", { evento_id: eventoID })
				]).then(([ types, KPI, ordersPerDay, surveys ]) => {
					setStatsTypes(types.data);
					setKpis(KPI.data);
					setOrdersPerDay(ordersPerDay.data);
				});
			}
		}

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, [negocio]);

    useEffect(() => {
        if (!programarEnabled) {
            const n = {...validationErrors};
            n["programar-disponibilidad"] = false;
            setValidationErrors(n);
        }
    }, [ programarEnabled ]);

	useEffect(() => {
		if (negocioID) {
			APIAdminRequest("get-negocio", { id: negocioID, locale: L10n.GetLanguage() }).then((response) => {
				setNegocio(response.data);
			});
		} else {
			location.href = "/admin/negocios/" + negocioID;
		}

		document.body.classList.add("has-header");

		return () => {
			document.body.classList.remove("has-header");
		};
	}, []);

	const save = useCallback((notifySaved) => {
		let validated = true;
		const n = {...validationErrors};
		for (let key of ["image_url", "nombre", "description"]) {
			if (!evento[key]) {
				validated = false;
				n[key] = true;
			}
		}

        if (programarEnabled && timeTable.reduce((a, v) => a + v.length, 0) == 0) {
            n["programar-disponibilidad"] = true;
            validated = false;
        } else {
            n["programar-disponibilidad"] = false;
        }

        if (evento.offers_preorder) {
            if (Object.keys(evento.extras_menu).length == 0) {
                validated = false;
                n["extras_menu"] = true;
            } else {
                Object.keys(evento.extras_menu).forEach(categoryName => {
                    const category = evento.extras_menu[categoryName];
                    if (!categoryName?.length || !category.items?.length) {
                        validated = false;
                        n["extras_menu"] = true;
                    }

                    if (validated) {
                        category.items.forEach(item => {
                            if (!item.description?.length || !item.image_url?.length || !item.name?.length) {
                                validated = false;
                                n["extras_menu"] = true;
                            }
                        });
                    }
                });
            }
        }

		setValidationErrors(n);

		if (!validated) {
			UI.ShowNotification("Por favor rellena todos los campos obligatorios.");
			notifySaved(false);
			return;
		}

		dialogContext.openDialog(L10n.__("¿Seguro que quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), confirmed => {
			if (confirmed) {
				const n = {...evento};
				const promises = [];

				Object.values(n.extras_menu).forEach(category => {
					if (category.items) category.items.forEach(item => {
						if (item.image_url) {
							promises.push(AdminService.SaveUploadedImage(item.image_url));
						}
					});
				});
				
				Promise.all(promises).then(responses => {
					let index = 0;
					for (let i=0; i<Object.values(n.extras_menu).length; i++) {
						const category = Object.values(n.extras_menu)[i];
						if (category.items) for(let j=0; j<category.items.length; j++) {
							if (category.items[j].image_url) {
								if (responses[index].data) n.extras_menu[Object.keys(n.extras_menu)[i]].items[j].image_url = responses[index].data;
								index++;
							}
						}
					}

					AdminService.SaveUploadedImage(evento.image_url).then(response => {
						const url = response.data;
						const n = {...evento};
						if (url) n.image_url = url;
						n.automatic_availability = programarEnabled ? 1 : 0;
						n.enabled = n.enabled ? 1 : 0;
						APIAdminRequest("save-event", n).then(response => {
                            let redirect = false;
                            if (!n.id) {
                                n.id = response.data.id;
                                redirect = true;
                            }
							if (programarEnabled) {
								APIAdminRequest("save-availability-templates", { event_id: n.id, time_table: timeTable, template_id: evento.availability_template_id || null }).then(() => {
									notifySaved(true);
                                    if (redirect) {
                                        location.href = "/admin/negocios/" + negocioID + "/" + n.id;
                                    } else {
									    setEvento(n);
                                    }
								});
							} else {
								notifySaved(true);
                                if (redirect) {
                                    location.href = "/admin/negocios/" + negocioID + "/" + n.id;
                                } else {
								    setEvento(n);
                                }
							}
						});
					});
				});
			} else {
				notifySaved(false);
			}
		});
	}, [evento, programarEnabled, timeTable]);

	if (!evento || !negocio) {
		return <LoadingIndicator />;
	}

	return (
		<div className="editar-evento edit-page">
			<AdminBar onSave={save} />
			<div className="header">
				<h2>{evento.nombre ? evento.nombre : L10n.__("Nuevo evento")}</h2>
				{eventoID != 0 && <FormToggle tooltip={L10n.__((evento.enabled ? "Desactivar" : "Activar") + " evento")} large={true} defaultValue={evento.enabled} onChange={value => {
					const n = {...evento};
					n.enabled = value;
					setEvento(n);
				}}/>}
				{eventoID != 0 && <div style={{marginLeft:"1em"}} className="delete-evento-container">
					<MaterialIcon onClick={e => {
						dialogContext.openDialog(L10n.__("¿Seguro que quieres borrar este evento?"), L10n.__("Sí"), L10n.__("No"), confirmed => {
							if (confirmed) {
								APIAdminRequest("delete-event", { id: eventoID }).then(() => {
									location.href = "/admin/negocios/" + negocioID;
								});
							}
						});
					}} name="delete" tooltip={L10n.__("Borrar evento")} />
				</div>}
			</div>
			<Bloque className="columnas">
				<div className="columna">
					<ImageUpload
						className={validationErrors["image_url"] ? "error" : ""}
						recommendedWidth={600}
						recommendedHeight={300}
						maxFileSize={600}
						initialValue={evento.image_url}
						onChange={(value) => {
							const newEvento = { ...evento };
							newEvento.image_url = value;
							setEvento(newEvento);
						}}
					/>
					<div className="toggles">
						<div>
							Full booking
							<div data-tooltip={L10n.__("Una reserva de este evento conlleva la ocupación de todas las plazas.")} className={"has-tooltip form-toggle" + (evento.full_booking == 1 ? " active" : "")} onClick={() => {
								const n = {...evento};
								n.full_booking = n.full_booking == 1 ? 0 : 1;
								setEvento(n);
							}}/>
						</div>
						<div>
							{L10n.__("Disponibilidad múltiple")}
							<div data-tooltip={L10n.__("El cliente puede seleccionar más de una fecha consecutiva.")} className={"has-tooltip form-toggle" + (evento.disponibilidad_multiple == 1 ? " active" : "")} onClick={() => {
								const n = {...evento};
								n.disponibilidad_multiple = n.disponibilidad_multiple == 1 ? 0 : 1;
								setEvento(n);
							}}/>
						</div>
						<div>
							Extras
							<div data-tooltip={L10n.__("Mostrar la selección de extras al seleccionar este evento.")} className={"has-tooltip form-toggle" + (evento.offers_preorder == 1 ? " active" : "")} onClick={() => {
								const n = {...evento};
								n.offers_preorder = n.offers_preorder == 1 ? 0 : 1;
								setEvento(n);
							}}/>
						</div>
						<div>
							Cross-selling
							<div data-tooltip={L10n.__("Ofrece reservar en otros tipos de negocio complementarios al seleccionar este evento.")} className={"has-tooltip form-toggle" + (evento.offers_upselling == 1 ? " active" : "")} onClick={() => {
								const n = {...evento};
								n.offers_upselling = n.offers_upselling == 1 ? 0 : 1;
								setEvento(n);
							}}/>
						</div>
					</div>
				</div>
				<div className="columna">
					<div className="columnas">
						<Field
							placeholder={L10n.__("Nombre")}
							className={"expand" + (validationErrors["nombre"] ? " error" : "")}
							type="string"
							defaultValue={evento.nombre}
							maxLength={25}
							onChange={(value) => {
								const newEvento = { ...evento };
								newEvento.nombre = value;
								setEvento(newEvento);
							}}
						/>
						<select
							className="duracion"
							defaultValue={0}
							onChange={(e) => {
								const newEvento = { ...evento };
								newEvento.duration = e.target.value;
								setEvento(newEvento);
							}}>
							<option value={0}>N/A</option>
							<option value={15}>15 min</option>
							<option value={30}>30 min</option>
							<option value={45}>45 min</option>
							<option value={60}>1 h</option>
							<option value={75}>1 h 15 min</option>
							<option value={90}>1 h 30 min</option>
							<option value={105}>1 h 45 min</option>
							<option value={120}>2 h</option>
						</select>
						<Field
							className="precio"
							type="currency"
							defaultValue={evento.price > 0 ? evento.price : ""}
							onChange={(value) => {
								const newEvento = { ...evento };
								newEvento.price = value;
								setEvento(newEvento);
							}}
						/>
					</div>
					<Field placeholder={L10n.__("Dirección")} className="expand" type="string" disabled={true} defaultValue={negocio.address} onChange={(value) => {
						const newEvento = {...evento}
						newEvento.address = value;
						setEvento(newEvento);
					}} />
					<div className={"programar-container" + (validationErrors["programar-disponibilidad"] ? " error" : "")}>
						{programarEnabled != null && <FormToggle className="programar-enabled" defaultValue={programarEnabled} onChange={value => {
							setProgramarEnabled(value);
						}} />}
						<h2 className="programar-disponibilidad">{L10n.__("Programar disponibilidad")}</h2>
                        {programarEnabled === false && <div className="programar-disabled-notice">
                            <div>{L10n.__("Deberás dar de alta las fechas manualmente en el apartado Disponibilidad")}</div>
                        </div>}
						<DaySelect className={programarEnabled ? "" : "disabled"} onChange={selectedDays => setSelectedDays(selectedDays)} />
						<div className={(programarEnabled ? "" : "disabled ") + "timetable-editor"}>
							<div className="heading">
								<div>{L10n.__("Plazas")}</div>
								<div>{L10n.__("Hora")}</div>
							</div>
							{timeTable !== null && timeTable[selectedDays[0]].map((slot, slot_index) => {
								let equal = true;
								for (let i=1; i<selectedDays.length; ++i) {
									if (timeTable[selectedDays[i]].length != timeTable[selectedDays[0]].length) {
										equal = false;
										break;
									}
									
									for (let j=0; j<timeTable[selectedDays[0]].length; j++) {
										if (timeTable[selectedDays[i]][j].pax != timeTable[selectedDays[0]][j].pax ||
										timeTable[selectedDays[i]][j].time != timeTable[selectedDays[0]][j].time) {
											equal = false;
											break;
										}
									}
								}
								if (!equal) return null;

								return <div className="slot" key={slot._key}>
									<Field type="number" min={1} defaultValue={slot.pax} onChange={(value => {
										const modelDaySlots = timeTable[selectedDays[0]];
										const n = [...timeTable];
										for (let i=0; i<selectedDays.length; ++i) {
											n[selectedDays[i]] = [...modelDaySlots];
											n[selectedDays[i]][slot_index].pax = value;
										}
										setTimeTable(n);
									})} />
									<InputSelect
										comboMode={true}
										options={Helper.GenTimeOptions()}
										defaultValue={slot.time}
										onChange={(value) => {
                                            const modelDaySlots = timeTable[selectedDays[0]];
                                            const n = [...timeTable];
                                            for (let i=0; i<selectedDays.length; ++i) {
                                                n[selectedDays[i]] = [...modelDaySlots];
                                                n[selectedDays[i]][slot_index].time = value;
                                            }
                                            setTimeTable(n);
										}}
									/>
									<div className="delete-row" onClick={e => {
										const n = [...timeTable];
										for (let i=0; i<selectedDays.length; ++i) {
											n[selectedDays[i]].splice(slot_index, 1);
										}
										setTimeTable(n);
									}}>&times;</div>
								</div>;
							})}
							<div className="buttons">
								<div className="plus" onClick={e => {
									e.preventDefault();
									let equal = true;
									for (let i = 1; i < selectedDays.length; ++i) {
										if (timeTable[selectedDays[i]].length != timeTable[selectedDays[0]].length) {
											equal = false;
											break;
										}

										for (let j = 0; j < timeTable[selectedDays[0]].length; j++) {
											if (timeTable[selectedDays[i]][j].pax != timeTable[selectedDays[0]][j].pax ||
												timeTable[selectedDays[i]][j].time != timeTable[selectedDays[0]][j].time) {
												equal = false;
												break;
											}
										}
									}
									
									const n = [...timeTable];
									for (let i = 0; i < selectedDays.length; ++i) {
										if (!equal) n[selectedDays[i]] = [];
										n[selectedDays[i]].push({ pax: 1, time: "00:00", _key: Math.random() });
									}
									setTimeTable(n);
								}}><img src="/static/icons/plus.svg" /></div>
							</div>
						</div>
					</div>
				</div>
			</Bloque>
			<Bloque className="columnas" columnCount={1}>
				<div className="columna">
					<Field
						placeholder={L10n.__("Descripción del evento.")}
						className={"descripcion expand" + (validationErrors["description"] ? " error" : "")}
						type="text"
						maxLength={500}
						defaultValue={evento.description}
						onChange={(value) => {
							const newEvento = { ...evento };
							newEvento.description = value;
							setEvento(newEvento);

                            const n = {...validationErrors}
                            n["description"] = false;
                            setValidationErrors(n);
						}}
					/>
                    {evento.offers_preorder != 0 && <AccordionTab heading="Extras" className={"extras" + (validationErrors["extras_menu"] ? " error" : "")}>
							<JSONEditor
							    initialValue={evento.extras_menu || negocio.preorder_menu}
							    onChange={(data) => {
								const newEvento = { ...evento };
								newEvento.extras_menu = data;
								setEvento(newEvento);
							    }}
							    associative={true}
							    keyTitle="Categoría"
							    properties={[{ title: L10n.__("Descripción"), optional: true, key: "description", type: "string" }]}
							    listTitle={L10n.__("Productos")}
							    listProperties={[
								{ title: L10n.__("Nombre"), key: "name", type: "string" },
								{ title: L10n.__("Precio"), key: "price", type: "currency", halfColumn: true },
/*								{ title: L10n.__("Stock"), key: "stock", type: "number", toggleable: true, minimumValue: 0 },*/
								{ title: L10n.__("Descripción"), key: "description", type: "text", maxLength: 250 },
								{ title: L10n.__("Imagen"), key: "image_url", type: "image", recommendedWidth: 720, recommendedHeight: 360, maxFileSize: 600 * 1024 }
							    ]}
							/>
						    </AccordionTab>}
				</div>
			</Bloque>
			{eventoID > 0 && statTypes && kpis && ordersPerDay && <Bloque className="columnas estadisticas" columnCount={2} style={{paddingTop: 75, position: "relative"}}>
				<h2 style={{position: "absolute", left: 25, top: 25}}>{L10n.__("Estadísticas")}</h2>
				<div className="columna" style={{ height: 420 }}>
					<div>
						<h3>{L10n.__("Reservas por día de la semana")}</h3>
                    	<BarChart singleMode={true} ratios={ordersPerDay} />
					</div>
				</div>
				<div className="columna">
					<h3>{L10n.__("Indicadores de rendimiento")}</h3>
					<div className="kpi-container">
						<div className="item">
							<div className="value">{Helper.FormatAmount(Math.floor(kpis.averageAmountPerUser / 100))}</div>
							<div className="caption">{L10n.__("Tícket medio por persona")}</div>
						</div>
						<div className="item">
							<div className="value">{Math.round((kpis.recurrencia * 100 + Number.EPSILON) * 100 / 100)}%</div>
							<div className="caption">{L10n.__("Recurrencia")}</div>
						</div>
						<div className="item">
							<div className="value">{Math.round((kpis.upsellingRatio * 100 + Number.EPSILON) * 100 / 100)}%</div>
							<div className="caption">{L10n.__("Conversión upselling")}</div>
						</div>
						<div className="item">
							<div className="value">{Math.round((kpis.crossSellingRatio * 100 + Number.EPSILON) * 100 / 100)}%</div>
							<div className="caption">{L10n.__("Conversión cross-selling")}</div>
						</div>
					</div>
				</div>
			</Bloque>}
            {eventoID > 0 && surveyData && surveyData.map && <Bloque className="encuestas columnas" columnCount={1} style={{paddingTop: 75, position: "relative"}}>
				<h2 style={{position: "absolute", left: 25, top: 25}}>{L10n.__("Valoración del evento")}</h2>
                <div className="columna">
                    {surveyData.map((item, index) => {
                        return <div className="category" key={"survey-data-category" + index}>
                            <h3>{item.text}</h3>
                            <StarMeter defaultValue={item.value} />
                        </div>;
                    })}
                </div>
            </Bloque>}
		</div>
	);
}
