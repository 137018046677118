import "../../css/home.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "./Footer.jsx";
import ImagenFondo from "./ImagenFondo";
import { Content, ContentImage, GetJSON } from "./Content";
import Slider from "./Slider.jsx";
import Events from "../services/Events.jsx";
import Settings from "../services/Settings.js";
import LoadingIndicator from "./LoadingIndicator.jsx";
import WidgetFiltro from "./WidgetFiltro";
import SearchResultsProvider from "../services/SearchResultsProvider.jsx";
import Video from "./Video.jsx";
import L10n from "../services/Locale";

export default function Home() {
	const _searchResultsProvider = useRef(new SearchResultsProvider([], 4));
	const searchResultsProvider = _searchResultsProvider.current;
	const eventLoadQueue = useRef();

	const [homeInformationFirst, setHomeInformationFirst] = useState();
	const [slidesConfig, setSlidesConfig] = useState([]);
	const [filteredResults, setFilteredResults] = useState([]);
	const [venues, setVenues] = useState([]);
	const [ciudades, setCiudades] = useState([]);
	const [filtersActive, setFiltersActive] = useState(false);
	const [videoPlayed, setVideoPlayed] = useState(false);
	const [loading, setLoading] = useState(true);
	const [ eventsLoading, setEventsLoading ] = useState(true);

	function toggleVideoPlay() {
		const $video = $(".step-video video");
		if ($video.hasClass("playing")) {
			pauseVideo();
		} else {
			playVideo();
		}
	}

	async function playVideo() {
		const $video = $(".step-video video");
		const video = $video[0];

		if (!video) return;

		$video.off("click").on("click", function (e) {
			if ($video.hasClass("playing")) {
				$video.addClass("forced-pause");
			}
			toggleVideoPlay();
		});

		if (!$video.hasClass("playing")) {
			try {
				await video.play();
				$video.addClass("playing").removeClass("forced-pause");
				setVideoPlayed(true);
			} catch (error) {
				$video.removeClass("playing");
				setVideoPlayed(false);
			}
		}
	}

	function pauseVideo() {
		const video = $(".step-video video")[0];

		if (!video) return;

		video.pause();
		video.classList.remove("playing");
	}

	function onScroll(e) {
		const $video = $(".step-video video");

		if (!$(".step-video").length) {
			$(window).off("scroll", onScroll);
			return;
		}

		const videoTop = $(".step-video").offset().top;
		const videoBottom = videoTop + $(".step-video").height();
		const windowTop = $(window).scrollTop();
		const windowBottom = windowTop + window.innerHeight;

		if (windowBottom > videoTop && videoBottom > windowTop) {
			if (!$video.hasClass("forced-pause")) {
				playVideo();
			}
		} else {
			pauseVideo();
		}
	}

	function renderInformationSection() {
		return (
			<section className="information">
				<div className="columna">
					<ImagenFondo className="celda imagen" slug="home-imagen-paso-1" />
					<div className="celda">
						<Content slug="home-paso-1-titulo" containerClassName="section-subtitle" />
						<h2 className="section-title">
							<Content slug="home-subtitulo-paso-1" />
						</h2>
						<Content slug="home-texto-paso-1" containerClassName="p" />
						<div className="logos">
							<div>
								<ContentImage slug="home-paso-1-logo-1" />
							</div>
							<div>
								<ContentImage slug="home-paso-1-logo-2" />
							</div>
							<div>
								<ContentImage slug="home-paso-1-logo-3" />
							</div>
						</div>
					</div>
				</div>
				<div className="columna">
					<div className="celda">
						<Content slug="home-paso-2-titulo" containerClassName="section-subtitle" />
						<h2 className="section-title">
							<Content slug="home-titulo-paso-2" />
						</h2>
						<Content slug="home-subtitulo-paso-2" containerClassName="p" />
						<Content slug="home-texto-paso-2" containerClassName="p" />
						<div className="step-3-logos-row">
							<div>
								<ContentImage slug="home-paso-2-logo-1" containerClassName="steps-3-logo" />
							</div>
							<div>
								<ContentImage slug="home-paso-2-logo-2" containerClassName="steps-3-logo" />
							</div>
							<div>
								<ContentImage slug="home-paso-2-logo-3" containerClassName="steps-3-logo" />
							</div>
						</div>
						<div className="step-3-logos-row">
							<div>
								<ContentImage slug="home-paso-2-logo-4" containerClassName="steps-3-logo" />
							</div>
							<div>
								<ContentImage slug="home-paso-2-logo-5" containerClassName="steps-3-logo" />
							</div>
							<div>
								<ContentImage slug="home-paso-2-logo-6" containerClassName="steps-3-logo" />
							</div>
						</div>
					</div>
					<ImagenFondo className="celda imagen" slug="home-imagen-paso-2" />
				</div>
				<div className="columna">
					<ImagenFondo className="celda imagen" slug="home-imagen-paso-3" />
					<div className="celda">
						<Content slug="home-paso-3-titulo" containerClassName="section-subtitle" />
						<h2 className="section-title">
							<Content slug="home-titulo-paso-3" />
						</h2>
						<Content slug="home-subtitulo-paso-3" containerClassName="p" />
						<Content slug="home-texto-paso-3" containerClassName="p" />
						<div className="logos">
							<div>
								<ContentImage slug="home-paso-3-logo-1" />
							</div>
							<div>
								<ContentImage slug="home-paso-3-logo-2" />
							</div>
							<div>
								<ContentImage slug="home-paso-3-logo-3" />
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

	useEffect(() => {
		setFilteredResults(venues);
	}, [venues]);

	useEffect(() => {
		setLoading(true);

		GetJSON("configuracion-slides").then((json) => {
			setSlidesConfig(json);
			Settings.Get("HOME_INFORMATION_FIRST", 0).then((homeInformationFirst) => {
				setHomeInformationFirst(homeInformationFirst == 1);
				setLoading(false);
			});
		});

		Promise.all([
			Events.GetAll(),
			Events.GetCities()
		]).then(async ([venues, cities]) => {
			eventLoadQueue.current = 0;
			venues.forEach(venue => {
				eventLoadQueue.current += venue.events.length;
				venue.events.forEach(event => {
					event.availability = [];
					event.calendar = {};
					event.loadAlways = true;

					Events.GetAvailability(event.id).then(response => {
						event.availability = response.availability;
						event.calendar = response.calendar;
						event.loadAlways = false;

						searchResultsProvider.setElementList(venues);

						eventLoadQueue.current--;
						if (eventLoadQueue.current <= 0) {
							setEventsLoading(false);
						}
					});
				});
			});

			setCiudades(cities);
			setVenues(venues);
			searchResultsProvider.setElementList(venues);
		});

		$(function ($) {
			$(window).on("scroll", onScroll);
		});

		return () => {
			$(window).off("scroll", onScroll);
		};
	}, []);

	if (loading) return <LoadingIndicator />;

	searchResultsProvider.resetRenderingOffsets();
	searchResultsProvider.resetTitleRendering();

	return (
		<div className={"home" + (homeInformationFirst ? " information-first" : "")}>
			<Slider
				style={{
					opacity: slidesConfig.length ? 1 : 0
				}}
				effect="fade"
				delay={5000}
				slides={slidesConfig}
			/>

			{!homeInformationFirst && venues?.length > 0 && (
				<WidgetFiltro
					ciudades={ciudades}
					elements={venues}
					resultsProvider={searchResultsProvider}
					onFiltered={(filtered, filtersActive) => {
						setFilteredResults(filtered);
						setFiltersActive(filtersActive);
					}}
				/>
			)}

			{homeInformationFirst && filtersActive == false && renderInformationSection()}

			<section className="top-list bg-offwhite">
				{searchResultsProvider.getResults().length == 0 && !eventsLoading && <div className="no-results">{L10n.__("No se ha encontrado disponibilidad con esta combinación.")}</div>}
				{filtersActive && searchResultsProvider.renderDefaultRows(true, false, 100)}
				{searchResultsProvider.renderDefaultRows(!filtersActive, true, 2)}
			</section>

			{!homeInformationFirst && filtersActive == false && renderInformationSection()}

			<section className="top-list bg-offwhite">{searchResultsProvider.resetTitleRendering() && searchResultsProvider.renderDefaultRows(!filtersActive)}</section>

			<div className="row step bg-offgrey step-video">
				<div className="steps-inner">
					<Video slug="video-home" />
					{!videoPlayed && (
						<div
							className="play-button"
							onClick={(e) => {
								e.preventDefault();
								playVideo();
							}}
						/>
					)}
				</div>
			</div>

			<Footer />
		</div>
	);
}
