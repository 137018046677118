import { useEffect, useState, useContext, useCallback } from "react";
import Bloque from "./AdminBloque";
import FormInput from "./FormInput";

import { DialogContext } from "../context/DialogContext";

import L10n from "../services/Locale";
import AdminService from "../services/Admin";
import AdminBar from "./AdminBar";
import Table from "./Table";
import FormToggle from "./FormToggle";
import Field from "./Field";
import UserFinder from "./UserFinder";
import MaterialIcon from "./MaterialIcon";
import { APIAdminRequest } from "../services/API";

export default function AdminDescuentos(props) {
	const [discounts, setDiscounts] = useState([]);

	const dialogContext = useContext(DialogContext);

	useEffect(() => {
	}, [ discounts ]);

	useEffect(() => {
		AdminService.GetDiscountCodes().then(codes => {
			setDiscounts(codes);
		});

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	const save = useCallback(notifySaved => {
		dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}

			AdminService.SaveDiscountCodes(discounts).then(() => {
				notifySaved(true);
			});
		});
	}, [ discounts ]);

	return (
		<div className="admin-page admin-descuentos">
			<AdminBar onSave={save} />
			<div className="header">
				<a href="#" className="btn boton-nuevo-codigo" onClick={e => {
					e.preventDefault();
					const d = [...discounts];
					d.push({
						type: 0,
						amount: 0,
						code: "",
						active: true,
						uses: -1,
						user_id: 0,
						expiration_date: null
					});
					setDiscounts(d);
				}}>{L10n.__("Nuevo código")}</a>
			</div>
			<Bloque className="columnas">
				<div className="columna">
					<h2>{L10n.__("Códigos de descuento")}</h2>
					<Table
						header={[L10n.__("Activo"), L10n.__("Tipo"), L10n.__("Valor"), L10n.__("Código"), L10n.__("Usos"), L10n.__("Usuario"), L10n.__("Caducidad"), ""]}
						widths={[ 1, 1, 1, 1, 1, 1, 1 ]}
						alignment={[ "left", "center", "center", "center", "center", "center", "right" ]}
						items={discounts.map((discount, discount_index) => ({ data: discount, columns: [
							<FormToggle defaultValue={discount.active} onChange={value => {
								const d = [...discounts];
								d[discount_index].active = value;
								setDiscounts(d);
							}} />,
							<select defaultValue={discount.type} onChange={e => {
								const d = [...discounts];
								const prevType = d[discount_index].type;
								d[discount_index].type = e.target.value;
								setDiscounts(d);
							}} className="tipo-descuento">
								<option value="0">{L10n.__("Importe")}</option>
								<option value="1">{L10n.__("Porcentaje")}</option>
							</select>,
							<Field type="currency" currencySymbol={discount.type == 1 ? "%" : null} currencySymbolPlacement={discount.type == 1 ? "after" : null} defaultValue={discount.amount} onChange={value => {
								const d = [...discounts];
								d[discount_index].amount = value;
								setDiscounts(d);
							}} />,
							<Field type="string" defaultValue={discount.code} onChange={value => {
								const d = [...discounts];
								d[discount_index].code = value;
								setDiscounts(d);
							}} />,
							<Field type="number" min={0} defaultValue={discount.uses} onChange={value => {
								const d = [...discounts];
								d[discount_index].uses = value;
								setDiscounts(d);
							}} />,
							<UserFinder allowAny={true} defaultValue={discount.user_id} onChange={value => {
								const d = [...discounts];
								d[discount_index].user_id = value;
								setDiscounts(d);
							}} />,
							<Field type="date" defaultValue={discount.expiration_date} onChange={value => {
								const d = [...discounts];
								d[discount_index].expiration_date = value;
								setDiscounts(d);
							}} />,
							<MaterialIcon style={{fontSize: 21}} name="delete" onClick={e => {
								e.preventDefault();
								dialogContext.openDialog(L10n.__("¿Seguro que deseas borrar este código de descuento?"), L10n.__("Sí"), L10n.__("No"), confirmed => {
									if (confirmed) {
										APIAdminRequest("delete-discount-code", { id: discount.id }).then(response => {
											AdminService.GetDiscountCodes().then(codes => {
												setDiscounts(codes);
											});
										});
									}
								});
							}} />
						]}))}
					/>
				</div>
			</Bloque>
		</div>
	);
}
