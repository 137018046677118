import { useState, useEffect } from "react";
import Helper from "../services/Helper";
import MaterialIcon from "./MaterialIcon";
import { APIAdminRequest } from "../services/API";
import AdminService from "../services/Admin";
import WidgetAttachmentLimits from "./WidgetAttachmentLimits";

export default function ImageUpload(props) {
	const [imageURL, setImageURL] = useState((props.initialValue && props.initialValue?.replace("/static/images/", "")) || "");
	const [ uploadedFileSize, setUploadedFileSize ] = useState(0);

	const sizeMode = props.sizeMode || "contain";

	const empty = imageURL == "";

    function getNormalizedURL(url) {
        url = (imageURL[0] == "/" || imageURL.startsWith("http")) ? imageURL : "/static/images/" + imageURL;

        if (location.hostname == "localhost" && imageURL[0] == "/") {
            url = "http://localhost:8081" + url;
        }

        return url;
    }

    useEffect(() => {
        if (!imageURL.length) return;

        const img = document.createElement("img");
        img.onerror = () => {
            console.error(getNormalizedURL(imageURL) + " not found.");
            setImageURL("");
        };
        img.src = getNormalizedURL(imageURL);
    }, [imageURL]);

	return (
		<div
			className={"image-upload" + (props.className ? " " + props.className : "") + (empty ? " empty" : "")}
			style={{ height: props.height || "", backgroundSize: sizeMode, backgroundImage: "url(" + getNormalizedURL(imageURL) + ")" }}
			onClick={(e) => {
				$(e.target.parentNode).find("input[type=file]").trigger("click");
			}}>
			<WidgetAttachmentLimits alwaysShow={props.alwaysShowLimitsInfo} positionOffset={props.limitsInfoPositionOffset} position={props.limitsInfoPosition} fileFormats={props.fileFormats || ["png", "jpg"]} maxFileSize={props.maxFileSize} recommendedWidth={props.recommendedWidth} recommendedHeight={props.recommendedHeight} uploadedFileSize={uploadedFileSize} />
			<div className="overlay"></div>
			<img src={getNormalizedURL(imageURL)} />
			<div className="add-icon">
				<MaterialIcon name="library_add" />
			</div>
			<input
				type="file"
				id="file-input"
				accept="image/x-png,image/gif,image/jpeg,image/svg+xml"
				onChange={(e) => {
					if (e.target.files && e.target.files[0]) {
						AdminService.ValidateUploadSize(e.target.files[0].size).then(() => {
							APIAdminRequest("upload-image", { file: e.target.files[0] }, false).then(response => {
                                if (response.status) {
                                    setImageURL(response.data.replace("/static/images", ""));
                                    if (props.onChange) {
                                        props.onChange(response.data);
                                    }
                                }
							});
						});
					} else {
						setImageURL(response.data.replace("/static/images", ""));
						if (props.onChange) {
							props.onChange(response.data);
						}
					}
				}}
			/>
		</div>
	);
}
