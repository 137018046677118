import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
	ArcElement,
	Legend,
	Tooltip
} from 'chart.js';

import { Bar, Doughnut } from 'react-chartjs-2';
import Helper from '../../services/Helper';
import L10n from "../../services/Locale";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement);

export default function DoughnutChart(props) {
    function getOrCreateLegendList(chart, id) {
		const legendContainer = document.getElementById(id);
		let listContainer = legendContainer.querySelector('ul');

		if (!listContainer) {
			listContainer = document.createElement('ul');
			listContainer.style.margin = 0;
			listContainer.style.padding = 0;

			legendContainer.appendChild(listContainer);
		}

		return listContainer;
	}

	function getOrCreateTooltip(chart) {
		let tooltipEl = chart.canvas.parentNode.querySelector('div');
		
		if (!tooltipEl) {
			tooltipEl = document.createElement('div');
			tooltipEl.style.pointerEvents = 'none';
			tooltipEl.classList.add("tooltip");	
			tooltipEl.style.position = 'absolute';
			tooltipEl.style.transform = 'translate(-50%, 0)';
			tooltipEl.style.transition = 'all .3s';
			chart.canvas.parentNode.appendChild(tooltipEl);
		}
		
		return tooltipEl;
	};

    return <div className="chart-container">
        <div>
            <Doughnut
                data={{
                    labels: props.types.map(t => Helper.UCFirst(t.plural)),
                    datasets: [{
                        label: L10n.__('Tipo de reserva'),
                        data: props.types.map(t => Math.round(props.ratios.find(r => r.venue_type_id == t.id)?.ratio * 10000) / 100),
                        borderWidth: 1,
                        backgroundColor: ['#36a2eb', '#4bc0c0', '#ffcd56', '#ff9f40', '#ff6384'],
                    }]
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                        htmlLegend: {
                            containerID: 'doughnut-legend-container'
                        },
                        tooltip: {
                            enabled: false,
                            position: 'nearest',
                            external: (context) => {
                                // Tooltip Element
                                const {chart, tooltip} = context;
                                const tooltipEl = getOrCreateTooltip(chart);
                                
                                // Hide if no tooltip
                                if (tooltip.opacity === 0) {
                                    tooltipEl.style.opacity = 0;
                                    return;
                                }
                                    
                                while (tooltipEl.firstChild) {
                                    tooltipEl.removeChild(tooltipEl.firstChild);
                                }
                                
                                // Set Text
                                if (tooltip.body) {
                                    tooltipEl.innerHTML = '<span>' + tooltip.title + '</span><span>|</span><span>' + tooltip.dataPoints[0].raw + '%</span>';
                                }
                                
                                const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
                                
                                // Display, position, and set styles for font
                                tooltipEl.style.opacity = 1;
                                tooltipEl.style.left = positionX + tooltip.caretX + 'px';
                                tooltipEl.style.top = positionY + tooltip.caretY - 50 + 'px';
                            }
                        }
                    }
                }}
                plugins={[ Legend, Tooltip, {
                    id: 'htmlLegend',
                    afterUpdate(chart, args, options) {
                        const ul = getOrCreateLegendList(chart, options.containerID);

                        // Remove old legend items
                        while (ul.firstChild) {
                            ul.firstChild.remove();
                        }

                        // Reuse the built-in legendItems generator
                        const items = chart.data.labels.map((text, index) => {
                            return {
                                index,
                                datasetIndex: 0,
                                fillStyle: chart.data.datasets[0].backgroundColor[index],
                                strokeStyle: "black",
                                lineWidth: 1,
                                fontColor: "black",
                                hidden: !chart.getDataVisibility(index),
                                text
                            };
                        });

                        items.forEach(item => {
                            const li = document.createElement('li');
                            li.style.alignItems = 'center';
                            li.style.cursor = 'pointer';
                            li.style.display = 'flex';
                            li.style.flexDirection = 'row';
                            li.style.marginLeft = '10px';

                            li.onclick = () => {
                                const {type} = chart.config;
                                if (type === 'pie' || type === 'doughnut') {
                                // Pie and doughnut charts only have a single dataset and visibility is per item
                                    chart.toggleDataVisibility(item.index);
                                } else {
                                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                                }
                                chart.update();
                            };

                            // Color box
                            const boxSpan = document.createElement('span');
                            boxSpan.style.background = item.fillStyle;
                            boxSpan.style.borderColor = item.strokeStyle;
                            boxSpan.style.borderWidth = item.lineWidth + 'px';
                            boxSpan.style.display = 'inline-block';
                            boxSpan.style.height = '20px';
                            boxSpan.style.marginRight = '5px';
                            boxSpan.style.width = '20px';
                            boxSpan.style.transition = 'all .1s ease';
                            boxSpan.style.filter = item.hidden ? 'saturate(0)' : 'none';
                            boxSpan.style.opacity = item.hidden ? 0.25 : 1;

                            // Text
                            const textContainer = document.createElement('p');
                            textContainer.style.color = item.fontColor;
                            textContainer.style.margin = 0;
                            textContainer.style.padding = 0;
                            textContainer.style.transition = 'all .1s ease';
                            textContainer.style.opacity = item.hidden ? 0.25 : 1;

                            const text = document.createTextNode(item.text);
                            textContainer.appendChild(text);

                            li.appendChild(boxSpan);
                            li.appendChild(textContainer);
                            ul.appendChild(li);
                        });
                    }
                }]}
            />
        </div>
        <div id="doughnut-legend-container" className="legend-container" />
    </div>;
}
