import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    ArcElement,
    Legend,
    Tooltip
} from 'chart.js';

import Helper from '../../services/Helper';
import { Bar, Doughnut } from 'react-chartjs-2';
import L10n from '../../services/Locale';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement);

export default function BarChart(props) {
    const colors = ['#36a2eb', '#4bc0c0', '#ffcd56', '#ff9f40', '#ff6384'];
    
    function getOrCreateTooltip(chart) {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');
        
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.classList.add("tooltip");	
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .3s';
            chart.canvas.parentNode.appendChild(tooltipEl);
        }
        
        return tooltipEl;
    };
    
    return <div className="chart-container bar-chart">
        <div>
            <Bar
                data={{
                    labels: [L10n.__('Lunes'), L10n.__('Martes'), L10n.__('Miércoles'), L10n.__('Jueves'), L10n.__('Viernes'), L10n.__('Sábado'), L10n.__('Domingo')],
                    datasets: props.singleMode ? [
                            {
                                label: 'Reservas',
                                data: [1, 2, 3, 4, 5, 6, 0].map(day => {
                                    return props.ratios[day].ratios.length > 0 ? props.ratios[day].ratios[0].count : 0;
                                }),
                                borderWidth: 1,
                                backgroundColor: props.color || '#20315b',
                            }
                        ] : props.types.map((type, idx) => {
                            return {
                                label: Helper.UCFirst(type.plural),
                                data: [1, 2, 3, 4, 5, 6, 0].map(day => {
                                    return props.ratios[day].ratios.find(item => item.type == type.plural)?.count || 0;
                                }),
                                borderWidth: 1,
                                backgroundColor: colors[idx]
                            }    
                        })
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            display: true,
                            stacked: true
                        },
                        y: { display: false, stacked: true }
                    },
                    plugins: {
                        tooltip: {
                            enabled: false,
                            position: 'nearest',
                            external: (context) => {
                                // Tooltip Element
                                const {chart, tooltip} = context;
                                const tooltipEl = getOrCreateTooltip(chart);
                                
                                // Hide if no tooltip
                                if (tooltip.opacity === 0) {
                                    tooltipEl.style.opacity = 0;
                                    return;
                                }
                                    
                                while (tooltipEl.firstChild) {
                                    tooltipEl.removeChild(tooltipEl.firstChild);
                                }
                                
                                // Set Text
                                if (tooltip.body) {
                                    const typeCountByDay = tooltip.dataPoints[0].dataset.data
                                    const dayIndex = tooltip.dataPoints[0].dataIndex;
                                    
                                    const ratioForDay = dayIndex == 6 ? props.ratios[0] : props.ratios[dayIndex + 1];
                                    
                                    let ratioForType = 0;
                                    let countForType = 0;
                                    ratioForDay.ratios.forEach(ratio => {
                                        if (ratio.type?.toLowerCase() == tooltip.dataPoints[0].dataset.label?.toLowerCase()) {
                                            ratioForType += ratio.ratio;
                                            countForType += ratio.count;
                                        }
                                    })

                                    tooltipEl.innerHTML = props.singleMode ? '<span>' + typeCountByDay[dayIndex] + ' ' + L10n.__("reserva" + (typeCountByDay[dayIndex] == 1 ? "" : "s")) + '</span>' : '<span>' + tooltip.dataPoints[0].dataset.label + '</span><span>|</span><span>' + (props.displayCount ? countForType : (Math.round(ratioForType * 10000)/100) + '%') + '</span>';
                                }
                                
                                const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
                                
                                // Display, position, and set styles for font
                                tooltipEl.style.opacity = 1;
                                const rect = tooltipEl.getBoundingClientRect();
                                const initialPosition = positionX + tooltip.caretX;
                                const error = (rect.right) - window.innerWidth + 50;
                                tooltipEl.style.left = (error > 0 ? initialPosition - error : initialPosition) + 'px';
                                tooltipEl.style.top = positionY + tooltip.caretY - 50 + 'px';
                            }
                        }
                    }
                }}
                plugins={[ Tooltip ]}
            />
        </div>
    </div>;
}
